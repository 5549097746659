import React, { useEffect, useState } from "react";
import styles from "./CaseEcommerce.module.css";
import Menu from "../../menu";
import Footer from "../../footer";
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import ScrollToTopButton from "../../ScrollToTop";
import ContactForm from "./ContactForm";
import image1 from "../../assets/images/furniture-case2.png";
import image2 from "../../assets/images/fotoljbild1.webp";
import image3 from "../../assets/images/fotoljblom.webp";
import AOS from "aos";
import "aos/dist/aos.css";

function Banner({ caseTitle }) {
  const { t } = useTranslation();
  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>
            {caseTitle || t("Case_banner_text")}
          </h1>
          <h2 className={styles["banner-heading1"]}>
            {t("Case_banner_text3")}
          </h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function ImageTextComponent() {
  const { t } = useTranslation();
  return (
    <div className="imagetextcomponent">
      <div className={styles["image-text-container"]}>
        <div className={styles["text-section"]} data-aos="fade-up">
          <h2>{t("header_needs")}</h2>
          <p>{t("paragraph_1_needs")}</p>
          <p>{t("paragraph_2_needs")}</p>
        </div>
        <div className={styles["image-section"]} data-aos="fade-up">
          <img src={image3} alt="Example" />
        </div>
      </div>

      <div className={styles["image-container-wrapper"]} data-aos="fade-up">
        <div className={styles["image-container"]}>
          <img src={image2} alt="Example" />
        </div>
      </div>

      <div className={styles["image-text-container"]}>
        <div className={styles["image-section"]} data-aos="fade-up">
          <img src={image1} alt="Example" />
        </div>
        <div className={styles["text-section"]} data-aos="fade-up">
          <h2>{t("header_solution")}</h2>
          <p>{t("paragraph_1_solution")}</p>
          <p>{t("paragraph_2_solution")}</p>
          <p>{t("paragraph_3_solution")}</p>
        </div>
      </div>
    </div>
  );
}

const Testimonial = () => {
  return (
    <div className={styles["testimonial-container"]}>
      <div className={styles["testimonial-text"]}>
        Saigah har varit otroligt tillmötesgående och sett våra behov. De har
        anpassat sig och lyssnat till våra önskemål. Vi har fått en modern och
        stilren hemsida och har ökat vår försäljning på webben med ca 65%.
      </div>
      <div className={styles["testimonial-author"]}>
        Hampus Möllerström, Projektledare på Fåtölj Experten
      </div>
    </div>
  );
};

const CasesDetails = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Menu />
      <Banner />
      <ImageTextComponent />
      <ContactForm />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default CasesDetails;
