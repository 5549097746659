import styles from "./webbyra-sundsvall.module.css";
import Menu from "./menu";
import Footer from "./footer";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { FaBullhorn } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { CgWebsite } from "react-icons/cg";
import { RiSeoLine } from "react-icons/ri";
import { GiArtificialIntelligence } from "react-icons/gi";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faHandshake } from "@fortawesome/free-solid-svg-icons";
import house from "./assets/images/logans.webp";
import { FaDesktop, FaRobot } from "react-icons/fa";
import { VscMegaphone } from "react-icons/vsc";
import { MdWeb } from "react-icons/md";
import { GiMeshNetwork } from "react-icons/gi";

import timeline1 from "./assets/images/IMG_4706.webp";
import timeline2 from "./assets/images/IMG_0255.webp";
import timeline3 from "./assets/images/IMG_4695.webp";
import timeline4 from "./assets/images/IMG_0256.webp";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();
  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("Webbyrå Sundsvall")}</h1>
          <h2 className={styles["banner-heading1"]}>
            <Trans
              i18nKey="webagency"
              components={{
                // Here, 'a' tags with links are passed in the order they appear in the translation
                1: <a href="https://saigah.com/website" />,
                3: <a href="https://saigah.com/advertisement" />,
                5: <a href="https://saigah.com/advertisement" />,
                7: <a href="https://saigah.com/ai" />,
              }}
            />
          </h2>
          <a
            href="https://saigah.com/contact"
            className={styles["contact-button1"]}
          >
            <Trans>meetUsButton</Trans>
          </a>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function InformationSection() {
  return (
    <div className={styles["information-section"]}>
      <h1 className={styles["section-heading"]}>
        <Trans>Information1</Trans>
      </h1>
      <p className={styles["section-content"]}>
        <Trans>Information2</Trans>
      </p>
      <Link to="/contact" className={styles["contact-button"]}>
        <Trans>meetUsButton</Trans>
      </Link>
    </div>
  );
}

function ValueItem({ icon, title, description, link }) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <a href={link} className={styles["value-item-link"]}>
      <div
        className={`${styles["value-item"]} ${isFlipped ? styles.flipped : ""}`}
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div className={styles.front}>
          <h3>{title}</h3>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.learnMore}>
            Läs mer{" "}
            <span className={styles.arrow}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className={styles.back}>
          <h3>{title}</h3>
          <p>{description}</p>
          <div className={styles.learnMore}>
            Läs mer{" "}
            <span className={styles.arrow}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </a>
  );
}

const ServicesSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles.servicesSection}>
      <div className={styles.servicesSection2}>
        <div className={styles.servicesGrid}>
          <div className={styles.slideItem}>
            <ValueItem
              title={t("servicestitle1")}
              description={t("servicesdesc1")}
              link="/website"
              icon={<MdWeb />}
            />
          </div>
          <div className={styles.slideItem}>
            <ValueItem
              title={t("servicestitle2")}
              description={t("servicesdesc2")}
              link="/advertisement"
              icon={<RiSeoLine />}
            />
          </div>
          <div className={styles.slideItem}>
            <ValueItem
              title={t("servicestitle3")}
              description={t("servicesdesc3")}
              link="/advertisement"
              icon={<VscMegaphone />}
            />
          </div>
          <div className={styles.slideItem}>
            <ValueItem
              title={t("servicestitle4")}
              description={t("servicesdesc4")}
              link="/ai"
              icon={<GiMeshNetwork />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const initialSteps = [
  { id: 1, imageUrl: timeline1 },
  { id: 2, imageUrl: timeline2 },
  { id: 3, imageUrl: timeline3 },
  { id: 4, imageUrl: timeline4 },
];

const Timeline = () => {
  const { t } = useTranslation();
  const steps = initialSteps.map((step, index) => ({
    ...step,
    title: t(`steps.${index}.title`),
    content: t(`steps.${index}.content`),
  }));

  const [selectedStep, setSelectedStep] = useState(steps[0]);

  return (
    <div className={styles.timelineContainer}>
      <h1 classname={styles.timelinetitel}>{t("timelinetitel")}</h1>
      <div className={styles.details}>
        <div
          className={styles.stepImage}
          style={{ backgroundImage: `url(${selectedStep.imageUrl})` }}
        />
        <div className={styles.textContainer}>
          <h2>{selectedStep.title}</h2>
          <p>{selectedStep.content}</p>
        </div>
      </div>
      <div className={styles.timeline}>
        {steps.map((step) => (
          <div key={step.id} className={styles.timelineStep}>
            <div className={styles.timelineContent}>
              <div
                className={`${styles.circle} ${
                  selectedStep.id === step.id ? styles.active : ""
                }`}
                onClick={() => setSelectedStep(step)}
              ></div>
              <div className={styles.stepContent}>
                <h3
                  className={`${styles.undercircletitle} ${
                    selectedStep.id === step.id ? styles.active : ""
                  }`}
                >
                  {step.title}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function ContactForm() {
  const { t } = useTranslation();
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    organization: "",
    message: "",
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      !isChecked ||
      !formFields.name ||
      !formFields.email ||
      !formFields.phoneNumber ||
      !formFields.organization ||
      !formFields.message
    ) {
      setIsFormValid(false);
      return;
    }
    setIsLoading(true);
    try {
      await emailjs.sendForm(
        "service_7pyetow",
        "template_rmc1hc3",
        form.current,
        "HLnsmSouXQBApBnAj"
      );
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
      form.current.reset();
      setFormFields({
        name: "",
        email: "",
        phoneNumber: "",
        organization: "",
        message: "",
      });
    } catch (error) {
      console.log(error.text);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
    setIsFormValid(true);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsFormValid(true);
  };

  return (
    <div className={styles["contact-form-container"]}>
      <form ref={form} className={styles["contact-form"]} onSubmit={sendEmail}>
        <div className={styles["form-group"]}>
          <input
            type="text"
            id="name"
            name="name"
            placeholder={t("full_name")}
            value={formFields.name}
            onChange={handleInputChange}
            className={styles["form-control"]}
          />
        </div>
        <div className={styles["form-group"]}>
          <input
            type="email"
            id="email"
            name="email"
            placeholder={t("email")}
            value={formFields.email}
            onChange={handleInputChange}
            className={styles["form-control"]}
          />
        </div>
        <div className={styles["form-group"]}>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder={t("phone")}
            value={formFields.phoneNumber}
            onChange={handleInputChange}
            className={styles["form-control"]}
          />
        </div>
        <div className={styles["form-group"]}>
          <input
            type="text"
            id="organization"
            name="organization"
            placeholder={t("company_organization")}
            value={formFields.organization}
            onChange={handleInputChange}
            className={styles["form-control"]}
          />
        </div>
        <div className={styles["form-group"]}>
          <textarea
            id="message"
            name="message"
            placeholder={t("message")}
            value={formFields.message}
            onChange={handleInputChange}
            className={styles["form-control"]}
          ></textarea>
        </div>
        <div className={styles["form-group"]}>
          <div className={styles["checkbox-container"]}>
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="checkbox" id="checkboxDescription">
              I agree that my data submitted is processed according to the{" "}
              <a href="/gdprpage">GDPR policy</a>.
            </label>
          </div>
        </div>
        {!isFormValid && (
          <p className={styles["error-message"]}>
            {t("Please fill in all fields and accept the terms and conditions")}
          </p>
        )}
        <button
          type="submit"
          className={styles["submit-button-small"]}
          disabled={isLoading}
        >
          {isLoading ? t("Sending...") : t("submit")}
        </button>
      </form>
      {showPopup && (
        <div className={styles["success-popup"]}>
          <p>{t("Message sent successfully!")}</p>
        </div>
      )}
    </div>
  );
}

const ServiceHighlightsSection = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.serviceHighlightsSection}>
      <div className={styles.serviceImage}>
        <img src={house} alt="Saiagh" />
      </div>
      <div className={styles.serviceContent}>
        <h2 className={styles.serviceHeading}>{t("whyus")}</h2>
        <p className={styles.serviceParagraph}>{t("whyusdescription")}</p>

        <div className={styles.serviceItem1}>
          <FontAwesomeIcon icon={faTrophy} className={styles.serviceIcon1} />
          <div className={styles.itemtexts}>
            <strong>{t("whyus1")}</strong>
            <p>{t("whyusdescription1")}</p>
          </div>
        </div>
        <div className={styles.serviceItem1}>
          <FontAwesomeIcon icon={faHandshake} className={styles.serviceIcon1} />
          <div className={styles.itemtexts}>
            <strong>{t("whyus2")}</strong>
            <p>{t("whyusdescription2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function LandingPage() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Webbyrå Sundsvall - SAIGAH</title>
        <meta
          name="description"
          content="Välkommen till Webbyrå Sundsvall - din pålitliga webbyrå i Sundsvall. Vi erbjuder professionell webbdesign, utveckling och digital marknadsföringstjänster."
        />
        <meta
          name="keywords"
          content="webbyrå sundsvall, webbyrå sundsvall, webbdesign sundsvall, webbutveckling sundsvall, digital marknadsföring sundsvall"
        />
        <meta property="og:title" content="Webbyrå Sundsvall - SAIGAH" />
        <meta
          property="og:description"
          content="Välkommen till Webbyrå Sundsvall - din pålitliga webbyrå i Sundsvall. Vi erbjuder professionell webbdesign, utveckling och digital marknadsföringstjänster."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta
          property="og:url"
          content="https://saigah.com/webbyra-sundsvall"
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="SAIGAH" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Webbyrå Sundsvall - SAIGAH" />
        <meta
          name="twitter:description"
          content="Välkommen till Webbyrå Sundsvall - din pålitliga webbyrå i Sundsvall. Vi erbjuder professionell webbdesign, utveckling och digital marknadsföringstjänster."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        <link rel="canonical" href="https://saigah.com/webbyra-sundsvall" />
        {/* Assuming MyComponent is properly defined and used for setting language or other head tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="SAIGAH" />
        <meta name="publisher" content="SAIGAH" />
        <link rel="icon" href="https://saigah.com/favicon.ico" />
        <meta name="al:ios:url" content="app://saigah/webbyra-sundsvall" />
        <meta name="al:android:url" content="app://saigah/webbyra-sundsvall" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Webbyrå Sundsvall",
            description:
              "Välkommen till Webbyrå Sundsvall - din pålitliga webbyrå i Sundsvall. Vi erbjuder professionell webbdesign, utveckling och digital marknadsföringstjänster.",
            url: "https://saigah.com/webbyra-sundsvall",
            // Additional structured data relevant to the landing page
          })}
        </script>
      </Helmet>
      <ScrollToTopButton />
      <Menu />

      <Banner />
      <ServicesSection />
      <Timeline />
      <ServiceHighlightsSection />

      <div className={styles["info-contact-container"]}>
        <InformationSection />
        <ContactForm />
      </div>

      <Footer />
    </div>
  );
}

export default LandingPage;
