import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowUp } from "react-icons/io"; // Importing the Font Awesome arrow up icon
import styles from "./ScrollToTop.module.css"; // Assuming you have a CSS module for styles

function ScrollToTopButton() {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  return showButton ? (
    <button
      className={styles.myBtn}
      title={t("gotoTopButton")}
      onClick={scrollToTop}
    >
      <IoIosArrowUp />
    </button>
  ) : null;
}

export default ScrollToTopButton;
