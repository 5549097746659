import React, { useRef, useState } from "react";
import styles from "./CaseBot.module.css";
import Menu from "../../menu";
import Footer from "../../footer";
import { useTranslation } from "react-i18next";
import { FaArrowDown, FaPlay, FaPause } from "react-icons/fa";
import ScrollToTopButton from "../../ScrollToTop";
import ContactForm from "./ContactForm";
import chatbotImage from "./../../assets/images/test2.webp";
import sampleVideo from "./../../assets/videos/AnderssonChatbotTemplate.mp4";
import botImage from "./../../assets/images/bot3.png";

function Banner({ caseTitle }) {
  const { t } = useTranslation();
  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>
            {caseTitle || t("botCase_banner_text")}
          </h1>
          <h2 className={styles["banner-heading1"]}>
            {t("Case_banner_text2")}
          </h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function ImageTextComponent() {
  const { t } = useTranslation();
  return (
    <div className={styles["image-text-container"]}>
      <div className={styles["text-section"]}>
        <h2>{t("botCase_textHeader")}</h2>
        <p>{t("botCase_paragraph")}</p>
        <p>{t("botCase_paragraph2")}</p>
      </div>
      <div className={styles["image-section-one"]}>
        <img src={botImage} alt="Example" />
      </div>
    </div>
  );
}

function ImageTextComponentReversed() {
  const { t } = useTranslation();
  return (
    <div className={`${styles["image-text-container2"]} ${styles["reversed"]}`}>
      <div className={styles["text-section"]}>
        <h2>{t("botCase_textHeader2")}</h2>
        <p>{t("botCase_paragraph3")}</p>
        <p>{t("botCase_paragraph4")}</p>
        <p>{t("botCase_paragraph5")}</p>
      </div>
      <div className={styles["image-section"]}>
        <img src={chatbotImage} alt="Example" />
      </div>
    </div>
  );
}

function VideoTextComponent() {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={`${styles["video-text-container"]}`}>
      <div className={styles["text-section"]}>
        <h2>{t("botCase_textHeader3")}</h2>
        <p>{t("botCase_paragraph6")}</p>
        <p>{t("botCase_paragraph7")}</p>
      </div>
      <div className={styles["video-section"]}>
        <video
          ref={videoRef}
          className={styles.video}
          onClick={handlePlayPause}
        >
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {!isPlaying && (
          <button onClick={handlePlayPause} className={styles.playPauseButton}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
        )}
      </div>
    </div>
  );
}

function VideoTextComponentReversed() {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={`${styles["video-text-container"]} ${styles["reversed"]}`}>
      <div className={styles["text-section"]}>
        <h2>{t("botCase_textHeader2")}</h2>
        <p>{t("botCase_paragraph3")}</p>
        <p>{t("botCase_paragraph4")}</p>
      </div>
      <div className={styles["video-section"]}>
        <video
          ref={videoRef}
          className={styles.video}
          onClick={handlePlayPause}
        >
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {!isPlaying && (
          <button onClick={handlePlayPause} className={styles.playPauseButton}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
        )}
      </div>
    </div>
  );
}

function Testimonial() {
  const { t } = useTranslation();

  return (
    <div className={styles.testimonialContainer}>
      <p className={styles.testimonialText}>{t("testimonial_text")}</p>
      <p className={styles.author}>{t("testimonial_author")}</p>
    </div>
  );
}

function BlockSection() {
  return (
    <div className={styles.blockContainer}>
      <div className={styles.blockTextSection}>
        <h1 className={styles.blockHeading}>
          Let's talk about your AI automation needs.
        </h1>
        <p className={styles.blockSubHeading}>
          Book a call to start scaling your business!
        </p>
      </div>
      <div className={styles.blockImageSection}>
        <img
          src={chatbotImage}
          alt="AI Automation"
          className={styles.blockImage}
        />
      </div>
    </div>
  );
}
const CurvedArrow = () => (
  <svg
    className={styles.curvedArrow}
    width="700"
    height="700"
    viewBox="0 0 600 700"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <marker
        id="arrowhead"
        markerWidth="6"
        markerHeight="6"
        refX="3"
        refY="3"
        orient="auto"
      >
        <path d="M 0 0 L 5 3 L 0 6 L 3 3 Z" fill="#243e54" />
      </marker>
    </defs>
    <path
      d="M20 20 Q 20 300, 300 300 Q 560 300, 580 580"
      stroke="#243e54"
      strokeWidth="9"
      fill="none"
      markerEnd="url(#arrowhead)"
    />
  </svg>
);

const CurvedArrow2 = () => (
  <svg
    className={styles.curvedArrow}
    width="700"
    height="700"
    viewBox="0 0 600 700"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <marker
        id="arrowhead"
        markerWidth="6"
        markerHeight="6"
        refX="3"
        refY="3"
        orient="auto"
      >
        <path d="M 0 0 L 5 3 L 0 6 L 3 3 Z" fill="#243e54" />
      </marker>
    </defs>
    <path
      d="M20 20 Q 20 300, 300 300 Q 560 300, 580 580"
      stroke="#243e54"
      strokeWidth="9"
      fill="none"
      transform="scale(-1, 1) translate(-600, 0)"
      markerEnd="url(#arrowhead)"
    />
  </svg>
);

const CasesDetails = () => {
  return (
    <div>
      <Menu />
      <Banner />
      <ImageTextComponent />
      <div className={styles.arrowContainer}>
        <CurvedArrow />
      </div>
      <ImageTextComponentReversed />
      <div className={styles.arrowContainer}>
        <CurvedArrow2 />
      </div>
      <VideoTextComponent />
      <ContactForm />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default CasesDetails;
