import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "./languageselector.module.css";
import i18next from "./i18n";

const Modal = ({ children, onClose }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button onClick={onClose} className={styles.closeButton}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

const LanguageToggle = ({ selectedLanguage, onLanguageChange }) => {
  const handleToggle = (lang) => {
    if (selectedLanguage !== lang) {
      onLanguageChange(lang);
    }
  };

  return (
    <div className={styles.languageToggleContainer}>
      <div className={styles.toggleOption}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={selectedLanguage === "sv"}
            onChange={() => handleToggle("sv")}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={selectedLanguage === "sv" ? styles.activeLabel : ""}>
          Svenska
        </span>
      </div>
      <div className={styles.toggleOption}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={selectedLanguage === "en"}
            onChange={() => handleToggle("en")}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={selectedLanguage === "en" ? styles.activeLabel : ""}>
          Engelska
        </span>
      </div>
    </div>
  );
};

const ConsentToggle = ({ userConsent, onConsentChange }) => {
  const handleToggle = (consent) => {
    if (userConsent !== consent) {
      onConsentChange(consent);
    }
  };

  return (
    <div className={styles.toggleContainer}>
      <div className={styles.toggleOption}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={userConsent === "accepted"}
            onChange={() => handleToggle("accepted")}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={userConsent === "accepted" ? styles.activeLabel : ""}>
          Acceptera alla
        </span>
      </div>
      <div className={styles.toggleOption}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={userConsent === "declined"}
            onChange={() => handleToggle("declined")}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={userConsent === "declined" ? styles.activeLabel : ""}>
          Endast nödvändiga
        </span>
      </div>
    </div>
  );
};

const LanguageSelector = ({ show, onClose, onSaveLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("i18next") || "sv"
  );
  const [rememberChoice, setRememberChoice] = useState(false);
  const [userConsent, setUserConsent] = useState(null);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  useEffect(() => {
    const savedConsent = Cookies.get("user-consent");
    if (savedConsent) {
      setUserConsent(savedConsent);
    }
  }, []);

  useEffect(() => {
    if (show && userConsent) {
      setShowLanguageSelector(true);
    }
  }, [show, userConsent]);

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    i18next.changeLanguage(code);
    if (rememberChoice) {
      Cookies.set("i18next", code);
    }
  };

  const handleSave = () => {
    Cookies.set("i18next", selectedLanguage);
    Cookies.set("user-consent", userConsent, { expires: 365 });
    onClose();
    onSaveLanguage(selectedLanguage, true); // Remember choice always true
  };

  const handleCheckboxChange = (isChecked) => {
    setRememberChoice(isChecked);
  };

  const handleConsentChange = (consent) => {
    setUserConsent(consent);
    setShowLanguageSelector(true);
  };

  if (!show) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <div className={styles.combinedModal}>
        <div
          className={`${styles.cookieSection} ${
            showLanguageSelector ? styles.hidden : ""
          }`}
        >
          <h1>Vi värdesätter din integritet</h1>
          <p>
            Genom att klicka på "Acceptera alla" godkänner du vår användning av
            cookies för en bättre upplevelse.
          </p>
          <ConsentToggle
            userConsent={userConsent}
            onConsentChange={handleConsentChange}
          />
        </div>
        <div
          className={`${styles.languageSection} ${
            showLanguageSelector ? styles.visible : ""
          }`}
        >
          <p>Vänligen välj önskat språk.</p>
          <LanguageToggle
            selectedLanguage={selectedLanguage}
            onLanguageChange={handleLanguageChange}
          />

          {selectedLanguage && (
            <div className={styles.continueButtonContainer}>
              <button onClick={handleSave} className={styles.continueButton}>
                Continue with{" "}
                {selectedLanguage === "sv" ? "Svenska" : "Engelska"}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LanguageSelector;
