import styles from "./terms.module.css";
import Menu from "./menu";
import Footer from "./footer";
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("Terms")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("Terms1")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function TermsPage() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.contentContainer}>
      <div>
        <Helmet>
          <title>Användarvillkor - SAIGAH</title>
          <meta
            name="description"
            content="Läs SAIGAHs användarvillkor för att förstå reglerna och riktlinjerna för användning av vår webbplats och tjänster."
          />
          <meta
            name="keywords"
            content="användarvillkor, terms of service, user agreement, legal, terms and conditions, SAIGAH terms"
          />

          <meta property="og:title" content="Användarvillkor - SAIGAH" />
          <meta
            property="og:description"
            content="Läs SAIGAHs användarvillkor för att förstå reglerna och riktlinjerna för användning av vår webbplats och tjänster."
          />
          <meta property="og:image" content="https://saigah.com/logo192.png" />
          <meta property="og:url" content="https://saigah.com/terms" />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="SAIGAH" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Användarvillkor - SAIGAH" />
          <meta
            name="twitter:description"
            content="Läs SAIGAHs användarvillkor för att förstå reglerna och riktlinjerna för användning av vår webbplats och tjänster."
          />
          <meta name="twitter:image" content="https://saigah.com/logo192.png" />

          <link rel="canonical" href="https://saigah.com/terms" />

          <meta name="robots" content="index, follow" />

          <meta name="author" content="SAIGAH" />
          <meta name="publisher" content="SAIGAH" />
          <link rel="icon" href="https://saigah.com/favicon.ico" />

          <meta name="al:ios:url" content="app://saigah/terms" />
          <meta name="al:android:url" content="app://saigah/terms" />

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: "Användarvillkor",
              description:
                "Denna sida innehåller användarvillkor för SAIGAH, detta inkluderar användarnas rättigheter och ansvar.",
              url: "https://saigah.com/terms",
              // Additional structured data relevant to the terms of service page
            })}
          </script>
        </Helmet>
        <Menu />
      </div>
      <Banner />
      <div className={styles.gdprSection}>
        <h1>Terms of Service</h1>
        <p>
          <strong>Effective Date:</strong> 2023-11-25
        </p>
        <p>
          <strong>1. Acceptance of Terms</strong>
          <br />
          By accessing our website, you agree to be bound by these Terms of
          Service. If you disagree with any part of the terms, please do not use
          our Services.
        </p>

        <p>
          <strong>2. Service Description</strong>
          <br />
          We provide a variety of services, which may be updated, withdrawn, or
          amended without notice. Our Service is provided on an "as is" and "as
          available" basis.
        </p>

        <p>
          <strong>3. User Obligations</strong>
          <br />
          You agree to use our Service only for lawful purposes and in
          accordance with these Terms.
        </p>

        <p>
          <strong>4. Registration</strong>
          <br />
          Some parts of our Service may require you to register information. In
          these cases, you agree to provide truthful information and to keep
          your details secure and comply with the GDPR policy.
        </p>

        <p>
          <strong>5. Modifications to the Service and Prices</strong>
          <br />
          Prices for our services are subject to change without notice. We
          reserve the right at any time to modify or discontinue the Service
          without notice at any time if one of the parties does not fulfill
          their end of the contract.
        </p>

        <p>
          <strong>6. Intellectual Property</strong>
          <br />
          All content included on the Service, such as text, graphics, logos,
          and software, is the property of our company or its content suppliers
          and protected by international copyright laws.
        </p>

        <p>
          <strong>7. Termination</strong>
          <br />
          We may terminate your access to the Service without cause or notice,
          which may result in the forfeiture and destruction of all information
          associated with your account if one of the parties does not fulfill
          their end of the contract.
        </p>

        <p>
          <strong>8. Disclaimer of Warranties</strong>
          <br />
          Your use of the Service is at your sole risk. The Service is provided
          on an "as is" and "as available" basis without warranties of any kind,
          if no warranties has been agreed on between the two parties.
        </p>

        <p>
          <strong>9. Limitation of Liability</strong>
          <br />
          Our company shall not be liable for any direct, indirect, incidental,
          special, consequential or exemplary damages resulting from your use of
          the Service.
        </p>

        <p>
          <strong>10. Governing Law</strong>
          <br />
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          substantive laws of Sweden, without regard to its conflict of law
          principles. Furthermore, any disputes arising out of or in connection
          with these Terms shall be subject to the exclusive jurisdiction of the
          courts of Sweden. For consumers within the European Union, mandatory
          provisions of the law of the country in which you reside will also
          apply to this agreement.
        </p>

        <p>
          <strong>11. Changes to Terms</strong>
          <br />
          We reserve the right to update or change our Terms of Service at any
          time and you should check these Terms periodically. Your continued use
          of the Service after we post any modifications to the Terms on this
          page will constitute your acknowledgment of the modifications and your
          consent to abide and be bound by the modified Terms.
        </p>

        <strong>12. Contact Information</strong>
        <p>
          If you have questions or comments about this terms of service, please
          contact:
        </p>
        <p>
          SAIGAH
          <br />
          Sundsvall, SE
          <br />
          info@SAIGAH.com
          <br />
          +46 727 202 106
        </p>
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default TermsPage;
