import React, { useEffect, useState } from "react";
import Footer from "./footer";
import styles from "./advertisement.module.css";
import { Link, useLocation } from "react-router-dom";
import Menu from "./menu.js";
import { useTranslation, Trans } from "react-i18next";
import "font-awesome/css/font-awesome.min.css"; // Import the font-awesome CSS
import {
  FaSearch,
  FaGoogle,
  FaCode,
  FaMousePointer,
  FaChartBar,
  FaShareAlt,
  FaCommentDots,
} from "react-icons/fa";
import steg1 from "./assets/images/adv-steg1.webp";
import steg2 from "./assets/images/adv-steg2.webp";
import steg3 from "./assets/images/adv-steg3.webp";
import steg4 from "./assets/images/adv-steg4.webp";
import { FaArrowDown } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>
            {t("Marketing_banner_text")}
          </h1>
          <h2 className={styles["banner-heading1"]}>
            {t("Marketing_banner_text2")}
          </h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function ValueItem({ icon, title, description }) {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className={`${styles["value-item"]} ${isFlipped ? styles.flipped : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className={styles.front}>
        {icon}
        <h3>{title}</h3>
        <i className={`${styles.hoverIcon} fas fa-hand-pointer`}></i>
      </div>
      <div className={styles.back}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

function OurMarketing() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["values-section"]}>
      <div className={styles["title-container"]}>
        <h2 className={styles["values-title"]}>{t("marketingTitle")}</h2>
      </div>
      <div className={styles["values-container"]}>
        <ValueItem
          icon={<FaSearch size={50} color="#ffffff" />}
          title={t("MarketingTitle1")}
          description={t("MarketingDescription1")}
        />
        <ValueItem
          icon={<FaChartBar size={50} color="#ffffff" />}
          title={t("MarketingTitle2")}
          description={t("MarketingDescription2")}
        />
        <ValueItem
          icon={<FaShareAlt size={50} color="#ffffff" />}
          title={t("MarketingTitle3")}
          description={t("MarketingDescription3")}
        />
      </div>
    </div>
  );
}

function ContactStep() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animations in milliseconds
    });
  }, []);

  return (
    <div className={styles["contact-container2"]}>
      <div className={styles["contact-container"]}>
        <div className={styles["contact-title-div"]}>
          <h2 className={styles["contact-title"]}>{t("Step1-title")}</h2>
        </div>
        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg1}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>1</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Kontakt-ad")}</h3>
            <p className={styles["description-text"]}>
              {t("Step1-description-AD")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg2}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>2</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Skapande-ad")}</h3>
            <p className={styles["description-text"]}>
              {t("Step2-description-AD")}
            </p>
          </div>
        </div>

        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg3}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>3</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Lansering-ad")}</h3>
            <p className={styles["description-text"]}>
              {t("Step3-description-AD")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg4}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>4</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Maintenance-ad")}</h3>
            <p className={styles["description-text"]}>
              {t("Step4-description-AD")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewSection() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animations in milliseconds
    });
  }, []);

  return (
    <div className={styles["new-section"]}>
      <div className={styles["content-section"]} data-aos="fade-up">
        <h2>{t("NewSectionTitle")}</h2>
        <Trans
          i18nKey="NewSectionDescription"
          components={[
            <span
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            />,
          ]}
        />
        <Link to="/contact">
          <button className={styles["content-section-button"]}>
            {t("ButtonText")}
          </button>
        </Link>
      </div>
    </div>
  );
}

function FAQSection() {
  const { t } = useTranslation();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const questions = [
    { q: t("faqQuestion1-AD"), a: t("faqAnswer1-AD") },
    { q: t("faqQuestion2-AD"), a: t("faqAnswer2-AD") },
    { q: t("faqQuestion3-AD"), a: t("faqAnswer3-AD") },
    { q: t("faqQuestion4-AD"), a: t("faqAnswer4-AI") },
    { q: t("faqQuestion5-AD"), a: t("faqAnswer5-AD") },
    { q: t("faqQuestion6-AD"), a: t("faqAnswer6-AD") },
  ];

  return (
    <div className={styles["faq-section"]}>
      <h2>{t("FAQTitle")}</h2>
      <div className={styles["faq-grid"]}>
        <div>
          {questions.slice(0, questions.length / 2).map((item, index) => (
            <div
              key={index}
              className={styles["faq-item"]}
              onClick={() =>
                setActiveQuestionIndex(
                  index === activeQuestionIndex ? null : index
                )
              }
            >
              <h3 className={styles["faq-question"]}>{item.q}</h3>
              <div className={styles["icon-indicator"]}>
                <i
                  className={`fas fa-chevron-${
                    index === activeQuestionIndex ? "up" : "down"
                  }`}
                ></i>
              </div>
              {index === activeQuestionIndex && (
                <div>
                  <p className={styles["faq-answer"]}>{item.a}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div>
          {questions.slice(questions.length / 2).map((item, index) => {
            // Adjusting index for the second half
            let adjustedIndex = index + Math.ceil(questions.length / 2);
            return (
              <div
                key={adjustedIndex}
                className={styles["faq-item"]}
                onClick={() =>
                  setActiveQuestionIndex(
                    adjustedIndex === activeQuestionIndex ? null : adjustedIndex
                  )
                }
              >
                <h3 className={styles["faq-question"]}>{item.q}</h3>
                <div className={styles["icon-indicator"]}>
                  <i
                    className={`fas fa-chevron-${
                      adjustedIndex === activeQuestionIndex ? "up" : "down"
                    }`}
                  ></i>
                </div>
                {adjustedIndex === activeQuestionIndex && (
                  <div>
                    <p className={styles["faq-answer"]}>{item.a}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Advertisement = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.contentContainer}>
      <Menu />
      <Helmet>
        <link rel="canonical" href="https://saigah.com/advertisement" />
        <title>Vi driver din digitala marknadsföring</title>
        <meta
          name="description"
          content="Expertis inom SEO, SEM och sociala medier hos SAIGAH. Vi erbjuder skräddarsydda lösningar för att öka din synlighet online och driva trafik till din webbplats. Upptäck hur vi kan förstärka din digitala marknadsföringsstrategi."
        />
        <meta
          name="keywords"
          content="advertising, digital marketing, social media marketing, SEO, PPC, brand strategy, creative campaigns, content marketing, online advertising, reklam, digital marknadsföring, sociala medier marknadsföring, sökmotoroptimering, PPC, varumärkesstrategi, kreativa kampanjer, innehållsmarknadsföring, online-reklam"
        />
        <meta
          property="og:title"
          content="Vi driver din digitala marknadsföring"
        />
        <meta
          property="og:description"
          content="Expertis inom SEO, SEM och sociala medier hos SAIGAH. Vi erbjuder skräddarsydda lösningar för att öka din synlighet online och driva trafik till din webbplats. Upptäck hur vi kan förstärka din digitala marknadsföringsstrategi."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/advertisement" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Vi driver din digitala marknadsföring"
        />
        <meta
          name="twitter:description"
          content="Expertis inom SEO, SEM och sociala medier hos SAIGAH. Vi erbjuder skräddarsydda lösningar för att öka din synlighet online och driva trafik till din webbplats. Upptäck hur vi kan förstärka din digitala marknadsföringsstrategi."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        {/* Add additional meta tags as needed */}
      </Helmet>

      <Banner />
      <OurMarketing />
      <ContactStep />
      <FAQSection />
      <NewSection />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default Advertisement;
