import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import LanguageSelector from "./languageselector.js";

import Website from "./website.js";
import Ai from "./ai.js";
import Advertisement from "./advertisement.js";
import HomePage from "./homepage.js";
import About from "./about.js";
import Contact from "./contact.js";
import GdprPage from "./gdprpage.js";
import Terms from "./terms.js";
import Cookies1 from "./cookies.js";
import News from "./news.js";
import "./App.css";
import ArticleDetails from "./ArticleDetails.js";
import CaseEcommerce from "./components/cases/CaseEcommerce.js";
import CaseBot from "./components/cases/CaseBot.js";
import CaseLandingPage from "./components/cases/CaseLandingPage.js";
import ScrollToTop from "./ScrollToTop";
import NotFoundPage from "./notfoundpage";
import WebbyraSundsvall from "./webbyra-sundsvall.js";
import WebbyraStockholm from "./webbyra-stockholm.js";

const App = () => {
  const [languagePopup, setLanguagePopup] = useState(true);
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const savedLanguage = Cookies.get("userLanguage");
    const userConsent = Cookies.get("user-consent");

    if (savedLanguage) {
      setLanguagePopup(false);
    }

    if (!userConsent) {
      setShowCookieConsent(true);
    }
  }, []);

  const handleLanguageSave = (language, rememberChoice) => {
    if (rememberChoice) {
      Cookies.set("userLanguage", language, { expires: 7 });
    }
    setLanguagePopup(false);
    const userConsent = Cookies.get("user-consent");
    if (!userConsent) {
      setShowCookieConsent(true);
    }
  };

  const handleLanguageClose = () => {
    setLanguagePopup(false);
    const userConsent = Cookies.get("user-consent");
    if (!userConsent) {
      setShowCookieConsent(true);
    }
  };

  return (
    <>
      <div className="App">
        {languagePopup && (
          <LanguageSelector
            show={languagePopup}
            onClose={handleLanguageClose}
            onSaveLanguage={handleLanguageSave}
          />
        )}
        {!languagePopup && showCookieConsent && (
          <>
            <div className="overlay"></div>
          </>
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/website" element={<Website />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/advertisement" element={<Advertisement />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gdprpage" element={<GdprPage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<Cookies1 />} />
          <Route path="/news" element={<News />} />
          <Route path="/:slug" element={<ArticleDetails />} />
          <Route path="/case/e-commerce" element={<CaseEcommerce />} />
          <Route path="/case/ai-bot" element={<CaseBot />} />
          <Route path="/case/landingpage" element={<CaseLandingPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/webbyra-sundsvall" element={<WebbyraSundsvall />} />
          <Route path="/webbyra-stockholm" element={<WebbyraStockholm />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
