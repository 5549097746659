import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie"; // Make sure to import Cookies

const resources = {
  en: {
    translation: {
      services: "Services",
      website: "Web development",
      seo: "AI",
      service_maintenance: "Service & Maintenance",
      other_services: "Other Services",
      contact: "Contact",
      about_us: "About Us",
      news: "News",
      result_driven_webagency: "Contact Us",
      full_name: "Full Name:",
      company_organization: "Company/organization:",
      phone: "Phone:",
      email: "Email:",
      message: "Message:",
      submit: "Submit",
      share_your_thoughts: "Share Your Thoughts With Us",
      eager_to_learn:
        "We're eager to learn more about your venture and explore how we can collaborate with you on your journey.",
      services: "Services",
      website: "Web development",
      seo: "AI",
      service_maintenance: "Advertising",
      other_services: "Other Services",
      contact: "Contact",
      about_us: "About Us",
      news: "News",
      bannerHeading: "A results-driven web agency",
      aboutHeading: "Meet the A-Team",
      aboutUsTitle: "About our web agency",
      aboutUsParagraph1:
        "We are a dedicated team of experienced and passionate individuals in web development. With our combined expertise, we strive to create outstanding web applications that meet the unique needs of our customers. We believe in collaboration, creativity and innovative thinking to deliver breakthrough solutions.",
      aboutUsParagraph2:
        "Here at the web agency, we combine deep technical expertise with creative talent to deliver digital solutions of the highest quality. We are a passionate team with diverse backgrounds and experiences, which allows us to take on a variety of challenges and turn them into unique opportunities. We believe in creating meaningful and sustainable digital experiences that not only meet, but exceed our customers' expectations.",
      aboutUsClosingStatement:
        "With Saigah, your digital success is just a click away.",
      contactUsButton: "Contact Us",
      gotoTopButton: "Go to top",
      contact_information: "Contact Information",
      faq_question1: "How do I contact you?",
      faq_answer1: "Use the contact form or email us directly.",
      faq_question2: "How long does it take to get a response?",
      faq_answer2: "We typically respond within 24 hours.",
      result_driven_webagency1: "Got any questions?",
      bannerHeading1: "We are driven by your success.",
      "first-titel-home": "Web solutions designed",
      "first-titel-home1": "to propel your business growth",
      "service1-title": "Web development",
      "service2-title": "AI",
      "service3-title": "Advertising",
      "service1-description":
        "Create beautiful, user-friendly websites for your brand. Our design is both appealing and responsive for all devices.",
      "service2-description":
        "Optimize business processes with our AI solutions. We automate tasks and generate insights for increased efficiency.",
      "service3-description":
        "Utilize data-driven advertising for precise targeting and impactful ads. With strategic placements and monitoring, we enhance ROI and elevate your brand.",
      ourServices: "Our Core Competencies",
      servicesDescription: "Tailored solutions to boost your online presence",
      "check-box-text": "Optimize Your Business Processes for Peak Efficiency",
      "check-box-text2":
        "Enhance Your Profitability with Targeted Growth Solutions",
      "check-box-text3":
        "Customized Strategies Tailored to Your Unique Business Needs",
      "first-titel-home2": "Optimal Web Agency",
      "growth-title": "Achieve your full digital potential",
      "growth-description":
        "We help you develop your business digitally, contact us today and let us grow together.",
      "contact-button-text": "get started today!",
      ourServices2: "Our latest projects",
      "service11-title": "Down to Earth",
      "service12-title": "Purposefully Lean",
      "service13-title": "Strategy-Driven",
      "service14-title": "Space-Age Tech",
      "service11-description":
        "We value straightforward, no-nonsense communication and strive to foster an environment of open and productive collaboration.",
      "service12-description":
        "We are an agile crew. We're selective with our clients, and we personally invest in every project that we take on.",
      "service13-description":
        "We make strategic, data-driven design and content decisions to ensure that your solution provides an actual return on investment.",
      "service14-description":
        "We build responsive and accessible solutions on a fast, secure, and easy-to-use platform, and we provide rock-solid hosting and support.",
      servicesDescription2: "Take a closer look at some of our previous works.",
      WebPage1: "Web Development",
      WebPage2: "We develop modern websites that suit your needs and wishes.",
      Kontakt: "Contact",
      Skapande: "Creation",
      Lansering: "Launch",
      Kontakt2:
        "Initiate the process by reaching out to us. We take the time to understand your vision, discussing your goals and requirements to ensure a solid foundation for the project.",
      Skapande2:
        "Our dedicated team gets to work, designing and developing a unique solution that combines visual appeal with effective functionality, tailored specifically for your needs.",
      Lansering2:
        "After thorough testing and refinements, we launch your project. But our journey doesn't end here. We're always here for support and to ensure continued success.",
      "Step1-title": "This is how we work",
      "Step1-description":
        "The first step in our process is about the initial contact with you as a customer. During this stage, we dedicate ourselves to understanding your unique needs and desires. We discuss what you want to achieve with your website, the type of design and functionality you require, your available budget and a schedule for the project. This allows for smooth planning and ensures that we are on the same page from the start. Our goal is to create a website that reflects your vision and meets all your requirements.",
      "Step2-description":
        "During this stage, we move on to the actual construction of your website. What sets us apart is our iterative working method. This means that we work step by step and continuously refine the website based on feedback, so that you get a product that is adapted to your needs. We strongly believe in open communication and transparency. Therefore, we will maintain close cooperation and constant contact with you throughout the creation process. In this way, you can feel involved, give your views and make sure that the finished website will be exactly as you imagined.",
      "Step3-description":
        "When it's time to launch your website, our joint journey is far from over. The launch process is a critical stage, and we prioritize having you involved at every step. Before we officially launch the site, we make sure to go through everything together, to make sure everything looks and works as it should. With your feedback, we make sure to make the final adjustments and fine-tuning. When everything feels right and you are ready, we help with the official launch of your website. Throughout this process, transparency and communication are key words. We make sure that you feel safe, informed and satisfied with the final result.",
      "Website-showcase": "From <0>Vision</0> to <1>Reality</1>",
      "Sample-title": "Examples",
      "Showcase-description":
        "We create stylish, modern and dynamic pages that represent your company in the best way!",
      FAQTitle: "Frequent questions",
      faqQuestion1: "What types of websites do you build?",
      faqQuestion2: "How long does it take to develop a website?",
      faqQuestion3: "Are your websites mobile responsive?",
      faqQuestion4: "How much does a website cost?",
      faqQuestion5: "Do you offer support and maintenance post-launch?",
      faqQuestion6: "Can I update the content on my website myself?",
      faqAnswer1:
        "We specialize in crafting a variety of websites to cater to different business needs, from e-commerce platforms and portfolio sites to blogs and corporate websites. Our team has experience across several industries, ensuring your website is tailored for your specific audience and goals.",
      faqAnswer2:
        "The development time varies depending on the website's complexity and features. On average, a basic website takes between 2-4 weeks, while more complex sites with custom features can take several months. We always provide a time estimate after our initial consultation.",
      faqAnswer3:
        "Absolutely! All the websites we develop are fully responsive, ensuring they look and function perfectly on smartphones, tablets, and desktops. With the increasing importance of mobile browsing, we prioritize mobile-friendly design in all our projects.",
      faqAnswer4:
        "The cost of a website depends on various factors like its complexity, design requirements, and additional features. We offer various packages to suit different budgets and needs. After understanding your requirements, we will provide a transparent pricing proposal with no hidden fees.",
      faqAnswer5:
        "Yes, we do. We understand that a website isn't a one-off project but a dynamic platform that requires regular updates and maintenance. We offer various support and maintenance packages to ensure your website remains updated, secure, and functional.",
      faqAnswer6:
        "It depends on the website's structure and platform. Some of our websites are built on user-friendly platforms that allow content updates, while others might require technical expertise. Post-launch, we will clarify this and, if necessary, offer training sessions to guide you.",
      NewSectionTitle: "Does this sound interesting?",
      NewSectionDescription:
        "<p>If you're interested in exploring what our service can offer, please don't hesitate to reach out. We're committed to ensuring our solutions are a perfect fit for your needs. Contact us today for a <0>free</0> consultation, and let's discuss how we can best assist you.</p>",
      Maintenance: "Service & Maintenance",
      "Step4-description":
        "Maintenance is a crucial phase that follows after launching your website. In this step, we ensure that your site remains updated, functional, and secure. Regular checks, performance optimizations, and feature updates are carried out to keep your website at its best. We address any issues or glitches promptly, ensuring your users enjoy a seamless experience. It's our commitment to not only deliver but also to maintain and nurture your website, guaranteeing its longevity and your ongoing satisfaction.",
      ButtonText: "Contact Us",
      omoss1: "About Us",
      omoss2:
        "Welcome to Saigah. We combine tradition with innovation to always deliver services of the highest quality. Our passion: your needs at the center.",
      whoAreWeDescription1:
        "At the heart of our company lies innovation. Every day, we challenge the status quo, pushing the boundaries of what's possible. Our commitment to redefining standards has always been our driving force, setting us apart in a crowded marketplace.",
      whoAreWeDescription2:
        "We don't just deliver projects; we create value. Every piece of software, every strategy, and every design is meticulously crafted to not just meet but exceed our clients' expectations. Our ultimate reward? Witnessing our clients thrive in their industries with our solutions by their side.",
      whoAreWeDescription3:
        "Passion is the fuel that drives us. Whether it's a minor task or a major project, our dedication remains unwavering. Every member of our team pours their heart into their work, ensuring what we deliver is nothing short of excellence.",
      whoAreWeTitle: "Who are we?",
      valueTitleInnovation: "Innovation",
      valueTitleQuality: "Quality",
      valueTitleCollaboration: "Collaboration",
      valueDescriptionInnovation:
        "In a constantly evolving world, we value innovation and adaptability as core principles. We aim to be at the cutting edge of technology, always a step ahead.",
      valueDescriptionQuality:
        "Quality and excellence aren't just goals; they are our basic working standards. Each project is a new opportunity to put our expertise and skill to the test.",
      valueDescriptionCollaboration:
        "We believe that success is built on strong relationships. That's why customer relationships and collaboration are always in focus, to co-create enduring value together.",
      valuesTitle: "Our Values",
      whatWeDoDescription1:
        "We offer customized IT solutions to drive innovation and business development. Our services include everything from analysis and design to custom software development and app creation, and we use agile methods to quickly meet your needs.",
      whatWeDoDescription2:
        "Every project is a chance for us to collaborate with you. We focus on understanding your business challenges and goals, so we can tailor our solutions to fit precisely.",
      whatWeDoDescription3:
        "We strive to create long-term value, not just complete projects. With us as a partner, you get more than technical solutions; you get a committed team that wants to see your business succeed.",
      whatWeDoTitle: "What We Do",
      teamtitle: "Brains behind the operation",
      meetUsTitle: "Meet Us",
      meetUsDescription:
        "Get to know the team behind your next successful project. Schedule a meeting with us today to discuss your needs and explore the opportunities that await you.",
      meetUsButton: "Contact Us",
      "AI-tjanst1": "Elevate your business with AI",
      "AI-tjanst2":
        "Boost your business with our AI tools for automation and optimization, driving your next-level growth.",
      "valuesTitle-AI": "Your new AI partner",
      "valueTitleInnovation-AI": "Optimize",
      "valueTitleQuality-AI": "Automate",
      "valueDescriptionInnovation-AI":
        "Increase sales and strengthen customer relationships with AI that adapts to your company's unique needs. Always be one step ahead of the competition.",
      "valueDescriptionQuality-AI":
        "Automate time-consuming processes and lower your operating costs. Focus instead on strategic growth and innovation.",
      "Step1-description-AI":
        "Our first step together focuses on understanding your company's unique challenges and goals. We dive deep into what you want to achieve through AI - whether it's about increasing efficiency, improving customer experience, or cutting costs. We take into account your budget and timeline to tailor a solution that is perfect for you. By establishing common ground from the start, we can smoothly design an AI strategy that not only meets, but exceeds your expectations.",
      "Step2-description-AI":
        "In this phase, we move from planning to action, with a work methodology characterized by iteration and adaptation. We develop AI solutions incrementally, to continuously improve and adjust based on your feedback. Through close dialogue and open communication, we ensure that you are involved in every step of the development process. That way, you can be confident that the final AI solution will not only meet but also exceed expectations.",
      "Step3-description-AI":
        "The launch of your AI solution is not the end, it is the beginning of a new phase in our relationship. This important milestone is treated with utmost care. We go through every detail of the solution together to ensure that everything works flawlessly. Your feedback is crucial as we make the final tweaks. When you feel completely at ease, the official launch takes place. Transparency and open communication are watchwords throughout the process, and we ensure that you feel both safe and satisfied with the final result.",
      "Step4-description-AI":
        "Maintenance is not an afterthought, it is part of our long-term strategy for your AI solution. We make sure that it is not only updated, but also optimized and secure. Regular performance checks and necessary updates ensure that your system is always working at the highest level. In case of any problems, we act immediately, to guarantee a consistent and efficient user experience. Our task does not end with delivery; we are dedicated to the long-term care and maintenance of your AI solution, so that it continues to exceed your expectations.",
      "faqQuestion1-AI": "How the service work and what problems i solved?",
      "faqAnswer1-AI":
        "Our AI service utilizes machine learning and other advanced algorithms to address specific challenges your business might face, such as in customer service or data analysis. The service is designed to be scalable and can be customized to meet your company's unique needs and objectives.",
      "faqQuestion2-AI": "Is the service secure and GDPR-compliant?",
      "faqAnswer2-AI":
        "Absolutely, security and data protection are central to us. Our service meets all GDPR requirements and uses modern security technologies to ensure your data is safely stored and managed.",
      "faqQuestion3-AI": "How is it integrated with existing systems?",
      "faqAnswer3-AI":
        "Our service is designed for seamless integration into your existing technological ecosystem. We offer a robust detailed documentation and technical support to facilitate the integration process.",
      "faqQuestion4-AI": "How user-friendly is the service?",
      "faqAnswer4-AI":
        "We've developed our service with user-friendliness in mind. An intuitive user interface makes it easy to get started, and we also provide comprehensive training and support to ensure a smooth user experience.",
      "faqQuestion5-AI": "What technical expertise is required?",
      "faqAnswer5-AI":
        "Our service is designed to be as user-friendly as possible. We provide full training and technical support so you can maximize the value of the service even without in-depth technical knowledge.",
      "faqQuestion6-AI": "What is the cost?",
      "faqAnswer6-AI":
        "We offer various pricing models to fit different needs and budgets. For a personalized and tailored cost estimate, you're welcome to contact our sales team.",
      Marketing_banner_text: "We run your digital marketing ",
      Marketing_banner_text2:
        "We offer results-focused solutions which enhances your visibility and profitability. Your growth is our priority.",
      marketingTitle: "Our Specialties ",
      MarketingTitle1: "SEO",
      MarketingTitle2: "SEM",
      MarketingTitle3: "SoMe",
      MarketingDescription1:
        "Boost organic visibility with our SEO expertise: precise keyword research, on-page optimization, and strategic backlinks. Enhance rankings, drive traffic, and increase conversions.",
      MarketingDescription2:
        "Enhance visibility with our SEM expertise: effective PPC management, from keyword choice to ad design and budgeting. Drive quality traffic for superior ROI.",
      MarketingDescription3:
        "Amplify community engagement with our SoMe services: compelling content and targeted ads. Connect with audiences, foster brand loyalty, and boost engagement across platforms.",
      "faqQuestion1-AD": "What does your advertising service entail?",
      "faqAnswer1-AD":
        "Our advertising service offers businesses the opportunity to promote their products or services on various platforms such as social media, search engines, and other relevant websites, to increase visibility and reach a broader audience.",
      "faqQuestion2-AD": " How much does it cost?",
      "faqAnswer2-AD":
        "The cost of our advertising service varies depending on the scope and type of campaign. We offer different packages and customized solutions to meet specific needs and budgets. Please contact us for a tailored quote.",
      "faqQuestion3-AD": "How do you measure the results?",
      "faqAnswer3-AD":
        "We use various methods and tools to measure the results of a campaign, including click-through rate (CTR), conversion rate, impressions, and ROI (Return on Investment). We provide regular reports offering insights into campaign performance and recommendations for improvements.",
      "faqQuestion4-AD": "Can I choose on which platforms?",
      "faqAnswer4-AD":
        "Yes, you have the option to choose on which platforms your ads will be displayed. We will advise and recommend the most effective platforms based on your target audience and business goals, but the final decision will be yours.",
      "faqQuestion5-AD": " Is it possible to modify a campaign?",
      "faqAnswer5-AD":
        "Yes, it is absolutely possible to make adjustments to an ongoing campaign. We continuously monitor campaign performance and can quickly make the necessary modifications to optimize results.",
      "faqQuestion6-AD": "What kind of support do you offer?",
      "faqAnswer6-AD":
        "We offer full support throughout the entire process, from planning to implementation and evaluation of the campaign. Our dedicated team is available via email, phone, and meetings to answer questions, resolve issues, and discuss strategies to ensure your campaign is successful.",
      "Kontakt-ad": "Consulting & Strategy",
      "Skapande-ad": "Creation & Development",
      "Lansering-ad": "Launch & Monitoring",
      "Maintenance-ad": "Evaluation & Reporting",
      "Step1-description-AD":
        "During this critical initial stage, we conduct a comprehensive discussion with you to understand in detail your business needs, goals, expectations and budget. After a thorough analysis of your target group, products/services and market, we develop an adapted and targeted advertising strategy. The strategy includes the selection of the most effective advertising platforms, a well-thought-out target group segmentation, budget allocation and a clear schedule. This phase lays the foundation for the following steps and ensures that all parties have a clear and shared vision of the campaign.",
      "Step2-description-AD":
        "In this step, our creative team creates distinctive and engaging ad content that includes text, images, graphics and, where appropriate, multimedia elements such as video and animation. Any landing pages required for the campaign are also developed with a focus on usability and conversion optimization. The content created is carefully reviewed to ensure it aligns with your brand and meets the campaign's objectives. This step often involves several iterations and adjustments based on your feedback to ensure complete satisfaction.",
      "Step3-description-AD":
        "Once all content is approved and the overall strategy is in place, your campaign is launched on the selected platforms. After the launch, a continuous monitoring process begins where the performance of your campaign is closely monitored in real time. This includes monitoring key performance indicators such as click-through rate, conversion rate and ROI. Any deviations or opportunities to improve the effectiveness of the campaign are quickly identified and remedied immediately.",
      "Step4-description-AD":
        "In this final step, a comprehensive analysis of your campaign's overall performance and results is carried out. Detailed reports are created that highlight key success factors and areas for improvement, and present insights into the campaign's ROI. This in-depth evaluation provides both us and you with valuable knowledge and understanding that can be used to sharpen future advertising strategies and initiatives. You get concrete and measurable results as well as recommendations for further measures and strategic adjustments.",
      eager_to_learn1:
        "Fill in the form below, we will contact you as soon as we can",
      GDPR: "GDPR-policy",
      GDPR1: "Below you are able to read about our GDPR policy.",
      Terms: "Terms of service",
      Terms1: "Below you are able to read about our terms of service",
      aboutPageTitle: "About us",
      aboutPageDescription:
        "Welcome to Saigah. We combine tradition with innovation to always deliver services of the highest quality. Our passion: your needs at the center.",

      aiPageTitle: "Elevate Your Business with AI",
      aiPageDescription:
        "Boost your business with our AI tools for automation and optimization, driving your next-level growth.",
      websitePageTitle: "We Create Your Website",
      websitePageDescription:
        "Our team at the web agency crafts cutting-edge web and front-end solutions using contemporary frameworks for your company.",
      advertisementPageDescription:
        "We offer results-focused solutions which enhances your visibility and profitability. Your growth is our priority.",
      aiChatbotvideo:
        "Our AI-powered Customer service chatbots are designed to transform your support approach. Directly engaging with customers, they discern specific needs and provide bespoke responses. Automating routine queries, they allow your team to focus on complex issues, thereby boosting efficiency. Integrated effortlessly into your service systems, these chatbots track every interaction, aiding in customized follow-ups and nurturing stronger customer ties.\n\nThese smart chatbots don't just answer queries; they actively guide customers, offering real-time recommendations and solutions. Capable of handling a diverse array of questions, from basic FAQs to intricate troubleshooting, they ensure high customer satisfaction. Adopt our innovative chatbots to enhance your customer service and stay at the forefront of support technology.",
      contactPageTitle: "Contact us",
      contactPageDescription:
        "Get in touch with SAIGAH today. Contact us for customer support, inquiries about our services, or to schedule a consultation. We're here to help you with all your digital solution needs.",
      homePageTitle: "SAIGAH - Web solutions",
      homePageDescription:
        "Explore SAIGAH's range of digital services designed to enhance your business's online presence. From web development to AI-driven analytics, we provide cutting-edge solutions tailored to your needs.",
      "Cookie-title": "We value your privacy",
      "Cookie-desc":
        "By clicking {Accept All, you agree to our use of cookies for better browsing nand personalized content.",
      "Cookie-learn": "Read more",
      "Cookie-accept": "Accept all",
      "Cookie-reject": "Reject all",
      "footer-home": "Home",
      "footer-website": "Web development",
      "footer-ai": "AI",
      "footer-adv": "Advertising",
      "footer-about": "About us",
      "footer-contact": "Contact",
      "footer-terms": "Terms of service",
      "footer-contact-inf": "Contact information",
      "footer-service-inf": "Services",
      "footer-phone": "Phone: +46 727 202 106",
      Cookies: "Cookie policy",
      Cookies1: "Below you are able to read about our Cookie policy.",
      bannerButtonText: "Contact us",
      "Customer-service-Chatbot": "Customer aid",
      "Onboarding-Chatbot": "Assistant",
      "onboarding-chatbot-description":
        "Our Internal help chatbot is designed to revolutionize your internal support. By directly interacting with employees, it identifies specific needs and provides customized solutions. This AI-powered assistant automates standard questions, allowing your team to focus on more complex challenges and increase productivity. Seamlessly integrated into your internal system, the chatbot tracks every interaction for effective follow-up and improves collaboration within the company.\n\n These intelligent chatbots do more than just answer questions; they act as active guides, offering real-time recommendations and solutions. Capable of handling everything from simple inquiries to advanced troubleshooting, ensuring high satisfaction and efficiency in your business. Implement our innovative chatbot to improve your internal service and be at the forefront of workplace support technology.",
      WhyChatbot: "Why choose AI-driven chatbots?",
      WhyChatbot1:
        "We build your AI chatbots: Enhancing Efficiency, Personalizing Interactions, and Elevating Performance in Customer and Team Engagement.",
      "Argument1-title": "Availability",
      "Argument1-text":
        "With our chatbots your customers or internal staff is offered responses to inquires/questions at any time of the day or night. This can be crucial in todays world where especially customers expect quick and timely interactions. And unlike human operators, chatbots don't need breaks, which ensures consistent service whenever.",
      "Argument2-text":
        "By implementing chatbots, you can significantly reduce operational costs through, for example, reduced processing time. These chatbots can handle high volumes of interactions simultaneously, allowing your team to focus on the more complex issues. In this way, the workflow in your business is optimized.",
      "Argument2-title": "Cost effectiveness",
      "Argument3-title": "Customer experience",
      "Argument3-text":
        "Our chatbots are designed to offer a personalized and engaging customer experience. They can handle a variety of queries, from simple FAQs to more complex inquiries. The chatbots use natural language processing (NLP) which make them understand and respond in a conversational manner, making interactions more natural and enjoyable.",
      "Argument4-title": "Structured",
      "Argument4-text":
        "With our chatbots, your entire business gets an easy way to find data and information. In this way, you avoid wasting time searching through lots of documents to find the information you are looking for, which then saves time and money. The data you choose to post is handled simultaneously with the latest security standards to ensure its confidentiality.",
      "valuesTitle-AI1": "Tailor-made AI Chatbots",
      News_banner_text: "News",
      News_banner_text2: "Here we publish our articles.",
      "footer-news": "News",
      Article_banner_text: "Article",
      Article_banner_text2: "Below you can read the selected article.",

      insikter: "Our latest news",

      latestPostsTitle: "Our latest insights",
      Article_related_text: "Related articles",
      "404_banner_text": "404",
      "404_banner_text2":
        "You seem to have gone a bit wrong! The page you are looking for seems to have slipped away. Return to the homepage, or don't hesitate to contact our support if you need guidance to find the right one.",
      "valueTitleFast-website": "Performance",
      "valueTitleSecure-website": "Secure hosting",
      "valueDescriptionFast-website":
        "Our websites offer top performance with fast loading times and a smooth user experience, optimized for all devices.",
      "valueDescriptionSecure-website":
        "Our hosting offers peace of mind with strong security, high availability and reliable, fast performance, optimized for all your needs.",
      Information1: "Your next partner in Sundsvall",
      Information2:
        "If you have any questions about our services or wonder how we can assist you, don't hesitate to get in touch. You are welcome to request a free quote to get an idea of ​​our prices. <br/><br/>You can contact us by sending an email or by filling out our contact form. We look forward to hearing from you soon and hope to become your preferred web agency in Sundsvall!",
      "Webbyrå Sundsvall": "Web agency Sundsvall",
      webagency:
        "We are a web agency in Sundsvall offering comprehensive services in <1>web design</1>, <3>digital marketing</3>, <5>SEO</5>, and <7>AI</7>. Our goal is to strengthen your online presence, increase traffic to your website, and help you attract more customers. We look forward to the opportunity to discuss how we can improve your visibility on the internet. Although we are based in Sundsvall, we offer our services digitally to customers all over Sweden.",
      servicestitle1: "Web development",
      servicestitle2: "SEO",
      servicestitle3: "Advertising",
      servicestitle4: "AI",
      servicesdesc1:
        "We develop modern digital solutions within websites, e-commerce, apps and intranets.",
      servicesdesc2:
        "Our effective strategies increase online visibility and promote organic traffic through SEO.",
      servicesdesc3:
        "We create marketing campaigns that engage your target audience and increase conversions",
      servicesdesc4:
        "Our AI solutions automate processes and optimize operations for better results.",
      ourServices1: "Our services",
      whyus: "Why choose us?",
      whyusdescription:
        "Your success is our success, and we strive to build lasting partnerships based on trust and mutual growth.",
      whyus1: "Experience",
      whyusdescription1:
        "We have extensive experience in web development, digital marketing, and AI, and we use our expertise to deliver high-quality solutions tailored to your needs.",
      whyus2: "Engagement",
      whyusdescription2:
        "We are committed to your success and work closely with you to understand your goals and challenges, ensuring that our solutions meet your expectations.",
      readTime: "minute read",
      published: "Published",
      category: "Category",
      previous: "« previous",
      next: "next »",
      searchPlaceholder: "Search articles...",
      newestFirst: "Oldest first",
      oldestFirst: "Newest first",
      allCategories: "All categories",
      "Webbyrå Stockholm": "Web agency Stockholm",
      WebbyråStockholmDescription:
        "We are a web agency in Stockholm that focuses on web development, SEO, digital marketing and AI. Let us help you achieve your digital needs.",
      Information11: "Your next partner in Stockholm",
      Information21:
        "If you have any questions about our services or wonder how we can assist you, don't hesitate to get in touch. You are welcome to request a free quote to get an idea of ​​our prices. <br/><br/>You can contact us by sending an email or by filling out our contact form. We look forward to hearing from you soon and hope to become your preferred web agency in Stockholm!",
      "Footer-texten": "Web solutions designed to propel your business growth.",
      "footer-gdpr": "Usage of personal data",
      learnMore: "Read more",
      project1Title: "E-commerce website",
      project1Category: "Web/UX",
      project1Description:
        "We have developed a modern and user-friendly e-commerce platform for Fåtölj Experten. The website offers a wide range of high-quality armchairs, easy navigation, and a seamless shopping experience. With its responsive design and secure payment solutions such as Klarna, Visa, Mastercard, and PayPal, customers can easily find and purchase their dream armchairs from both computers and mobile devices.",
      project2Title: "AI chatbot for staff",
      project2Category: "AI/Machine Learning",
      project2Description:
        "Give your customers a reason to choose your brand over the countless others out there. We know that successful businesses need a distinct brand identity to separate them from their competition therefore our branding design will always excite, delight and draw customers back to you over and over again.",
      project3Title: "Business website",
      project3Category: "Web/UX",
      project3Description:
        "Give your customers a reason to choose your brand over the countless others out there. We know that successful businesses need a distinct brand identity to separate them from their competition therefore our branding design will always excite, delight and draw customers back to you over and over again.",
      website_description:
        "Complete solutions in web development, design and maintenance.",
      seo_description: "Improve your business with AI.",
      marketing_description:
        "Marketing services to increase your digital presence.",
      Case_banner_text: "E-commerce website",
      header_needs: "Demand",

      paragraph_1_needs:
        "Fåtölj Experten's previous website was difficult to navigate and felt outdated. There was a great demand to increase the user-friendliness for both visitors and administrators.",
      paragraph_2_needs:
        "Fåtölj Experten's main demand was to be able to easily and clearly display information about products, campaigns and customer reviews, as well as to facilitate navigation for both customers and administrators. Fotölj Experten offers a wide range of seating furniture, including armchairs, sofas and chairs. The website is also intended to act as a central location for customers who want to read blogs about interior design, get tips on furniture care or take advantage of special offers. There was also a great need to adapt the website for accessibility regarding both language choice and text size.",
      header_result: "Result",
      paragraph_1_result:
        "Following the implementation of the new design and functional improvements, Fåtölj Experten's website has experienced a significant increase in both user-friendliness and customer satisfaction. The modern and clean design has created an inviting and elegant atmosphere that is appreciated by visitors. The new product database has made it easier for customers to find and compare different seating furniture, which has led to an increase in conversion rates and sales.",

      paragraph_2_result:
        "The improved navigation has reduced site bounces and increased the time visitors spend exploring products and content. The blog section and furniture care tips have attracted more visitors and engaged them longer on the website. Additionally, special offers and promotions have become more visible and accessible, resulting in an increase in promotional sales.",

      header_solution: "Solution",
      paragraph_1_solution:
        "Saigah has prepared a completely new design and developed the Fötölj Experten's e-commerce website in accordance with the wishes for a modern and user-friendly site.",
      paragraph_2_solution:
        "The design, which was created in consultation with Fåtölj Experten, is modern, clean and simple, with a sense of elegance and comfort in the warm and inviting color choices. Saigah also developed a powerful product database that makes it easy for customers to find and compare different armchairs and seating furniture. Fåtölj Experten's online store was also updated to increase functionality and make it more educational and user-friendly.",
      paragraph_3_solution:
        "As accessibility is important to Fåtölj Experten, Saigah has also met the requirements regarding language selection by having the page translated into several different languages, as well as ensuring that the text is adjustable in terms of size.",
      landingpage_text: "Landing-page website",

      landingpage_text2:
        "Road Cargo's new landing page focuses on representing their brand.",
      landingpage_1_needs:
        "Road Cargo's previous website was difficult to navigate and felt outdated. There was a great demand to increase the user-friendliness for both visitors and administrators.",
      landingpage_2_needs:
        "Road Cargo's main demand was to be able to easily and clearly display information about services, campaigns and customer reviews, as well as to facilitate navigation for both customers and administrators. Road Cargo offers a wide range of transport solutions, including road transport, logistics and forwarding. The website is also intended to act as a central location for customers who want information about its efficient transport management or take advantage of existing special offers. There was also a great need to adapt the website for accessibility.",
      landingpage_1_solution:
        "The new website for Road Cargo has received a modern design and improved user-friendliness. A clean layout now makes it easy for visitors to navigate and find information about their services. To facilitate administration, the website now has a user-friendly CMS system that simplifies updating content.",
      landingpage_2_solution:
        "We have also implemented clear sections for services, information and customer reviews, making it easy for customers to find. To meet the needs of a wider audience, accessibility was adapted to meet all types of users.",
      landingpage_3_solution:
        "The new site also serves as a central place of administration for their efficient transport management, helping Road Cargo better meet customer needs and strengthen its market position.",
      valuesTitle1: "Basic principles we work from",
      valueTitleInnovation1: "Responsively customized",
      valueTitleQuality1: "Performance",
      valueTitleInnovation2: "Security",
      valueTitleQuality2: "Search optimized",
      valueTitleCollaboration2: "CMS",
      valueTitleCollaboration1: "Scalable",
      valueTitleResponsive: "Mobile adapted",
      valueResponsive1: "Fits all devices, all screen sizes",
      valueResponsive2: "Custom navigation on mobile",
      valueResponsive3: "Optimized UX",
      valueInnovation1: "Our pages are made with React, Javascript, and CSS",
      valueInnovation2: "Minimal load time",
      valueInnovation3: "Optimized code",
      valueInnovation11: "Scalable solutions with easy expansion",
      valueInnovation12: "Flexible costs depending on needs",
      valueInnovation13: "Customized cloud services",
      valueSecure1: "Secure hosting with AWS",
      valueSecure2: "Constant updates",
      valueSecure3: "Regular backup of your data",
      valueSearch1: "Keyword optimization for what you want to be seen on",
      valueSearch2: "Be first in the organic traffic",
      valueSearch3: "Make it easier for customers to find you online",
      info1left:
        "Web design, front-end & back-end integration, app development and ongoing support.",
      info1right:
        "We build comprehensive digital solutions that are adapted to our customers' unique needs. An offer of services in web design, front-end and back-end integration, app development and ongoing support.<br /><br /> We handle back-end integration, including database management, API integrations and security solutions. We have experience in developing robust and scalable web services that are adapted to meet the requirements of growing companies.",
      info3text:
        "Do you have a project going on? <underline>Let us</underline> check it out!",
      info3contactButtonText: "Contact us",
      "Argument1-list1": "24/7 access to information",
      "Argument1-list2": "Fast and consistent service",
      "Argument1-list3": "Reduce waiting times",
      "Argument2-list1": "Reduce operational costs",
      "Argument2-list2": "Handle high volume of interactions",
      "Argument2-list3": "Optimize workflow",
      "Argument3-list1": "Personal and engaging customer experience",
      "Argument3-list2": "Natural language processing",
      "Argument3-list3": "Improve customer satisfaction",
      "Argument4-list1": "Easy to find data and information",
      "Argument4-list2": "Save time and money",
      "Argument4-list3": "Secure and confidential",
      "chatbotDescription.point1":
        "Automate routine inquiries, freeing up your team for complex issues.",
      "chatbotDescription.point2":
        "Provides tailored responses and follow-ups, enhancing customer relationships.",
      "chatbotDescription.point3":
        "Easily integrates into your systems, tracking every interaction.",
      "chatbotDescription.point4":
        "Offers real-time guidance and solutions, boosting customer satisfaction.",
      "chatbotDescription.point5":
        "Handles both basic FAQs and advanced troubleshooting.",
      "chatbotDescription.point6":
        "Stay ahead in support technology with our innovative chatbots.",
      "explainAi.title": "We see the opportunity<br />for your automation.",
      "explainAi.paragraph1":
        "Are you curious about how AI or machine learning could be used for your business? Do you already have an idea or need guidance? We help you find opportunities on how this could benefit your business.",
      "explainAi.paragraph2":
        "Given today’s rapid development, it’s no longer a question of <em><strong>if</strong></em> AI can be used in your business, but <em><strong>how</strong></em>. Whether you want to streamline your processes, improve customer experience, or create new business opportunities, AI and machine learning can be the key to taking your business to the next level.",
      "explainAi.paragraph3":
        "Contact us for a free consultation, and together we can look at how AI can benefit you.",
      "explainAi.contactButton": "Contact us",
      "explainAi.iconData": "Data",
      "explainAi.iconModeling": "Modeling",
      "explainAi.iconEvaluate": "Evaluate",
      "chatbot.Customer-service-Chatbot": "Customer aid",
      "chatbot.customerService.title1": "24/7 Customer Support",
      "chatbot.customerService.description1":
        "Never miss a customer inquiry. Our chatbot provides round-the-clock support, ensuring your customers always receive the help they need.",
      "chatbot.customerService.title2": "Instant Responses",
      "chatbot.customerService.description2":
        "Automate your customer service with instant responses. The chatbot can handle FAQs, product information, and more, improving efficiency.",
      "chatbot.customerService.title3": "Multi-Channel Support",
      "chatbot.customerService.description3":
        "Engage with your customers on their preferred channels, whether it's through live chat, social media, or messaging apps.",
      "chatbot.customerService.title4": "Personalized Interactions",
      "chatbot.customerService.description4":
        "Deliver tailored experiences by personalizing interactions based on customer data, making every conversation relevant and engaging.",

      "chatbot.Onboarding-Chatbot": "Assistant",
      "chatbot.onboarding.title1": "Guided Onboarding",
      "chatbot.onboarding.description1":
        "Make onboarding a breeze with step-by-step guidance for new users. The chatbot walks users through the setup process with ease.",
      "chatbot.onboarding.title2": "Interactive Tutorials",
      "chatbot.onboarding.description2":
        "Provide interactive tutorials that help users understand and utilize your product’s features effectively from day one.",
      "chatbot.onboarding.title3": "Real-Time Assistance",
      "chatbot.onboarding.description3":
        "Offer real-time support during onboarding, ensuring that users get help when they need it most, without delays.",
      "chatbot.onboarding.title4": "Feedback Collection",
      "chatbot.onboarding.description4":
        "Gather user feedback during onboarding to continuously improve the process and enhance user satisfaction.",
    },
  },
  sv: {
    translation: {
      services: "Tjänster",
      website: "Webbutvekling",
      seo: "AI",
      service_maintenance: "Service & Underhåll",
      other_services: "Övriga Tjänster",
      contact: "Kontakt",
      about_us: "Om oss",
      news: "Nyheter",
      result_driven_webagency: "Kontakta Oss",
      full_name: "Fullständigt namn:",
      company_organization: "Företag/organisation:",
      phone: "Telefon:",
      email: "E-post:",
      message: "Meddelande:",
      submit: "Skicka in",
      share_your_thoughts: "Dela dina tankar med oss",
      eager_to_learn:
        "Vi är ivrig om att lära oss mer om ditt företag och utforska hur vi kan samarbeta med dig på din resa.",
      services: "Tjänster",
      website: "Webbutveckling",
      seo: "AI",
      service_maintenance: "Annonsering",
      other_services: "Andra tjänster",
      contact: "Kontakt",
      about_us: "Om oss",
      news: "Nyheter",
      bannerHeading: "En resultatdriven webbyrå",
      aboutHeading: "Möt A-Team",
      aboutUsTitle: "Om oss på webbyrån",
      aboutUsParagraph1:
        "Vi är ett dedikerat team av erfarna och passionerade individer inom webbutveckling. Med vår kombinerade expertis strävar vi efter att skapa enastående webbapplikationer som möter våra kunders unika behov. Vi tror på samarbete, kreativitet och innovativt tänkande för att leverera banbrytande lösningar.",
      aboutUsParagraph2:
        "Hos oss på Webbyrån kombinerar vi djup teknisk expertis med kreativ talang för att leverera digitala lösningar av högsta kvalitet. Vi består av ett passionerat team med olika bakgrunder och erfarenheter, vilket gör att vi kan ta oss an en mängd olika utmaningar och förvandla dem till unika möjligheter. Vi tror på att skapa meningsfulla och hållbara digitala upplevelser som inte bara uppfyller, utan överträffar våra kunders förväntningar.",
      aboutUsClosingStatement:
        "Med Saigah är din digitala framgång bara ett klick bort.",
      contactUsButton: "Kontakta oss",
      gotoTopButton: "Gå till toppen",
      contact_information: "Kontakt Information",
      faq_question1: "Hur kommer jag i kontakt med er?",
      faq_answer1:
        "Använd formuläret nedan eller kontakta oss direkt på telefon eller vår mail.",
      faq_question2: "Hur lång tid tar det innan man får svar?",
      faq_answer2: "Vi svarar inom 24 timmar.",
      result_driven_webagency1: "Har du några frågor?",
      bannerHeading1: "Vi drivs av eran framgång.",
      "first-titel-home": "Digitala lösningar",
      "first-titel-home1": "för att främja er affärstillväxt",
      "first-titel-home2": "Optimal Webbyrå",
      "service1-title": "Webbutveckling",
      "service2-title": "AI",
      "service3-title": "Annonsering",
      "service1-description":
        "Skapa snygga, användarvänliga webbplatser för ditt varumärke. Vår design är både tilltalande och lyhörd för alla enheter.",
      "service2-description":
        "Optimera affärsprocesser med våra AI-lösningar. Vi automatiserar uppgifter och genererar insikter för ökad effektivitet.",
      "service3-description":
        "Använd datadriven annonsering för exakt målgruppsträffning och effektiva annonser. Med strategisk placering och övervakning förbättrar vi avkastning och lyfter ditt varumärke.",
      ourServices: "Våra Kärnkompetenser",
      servicesDescription:
        "Skräddarsydda lösningar för att öka din onlinenärvaro",
      "check-box-text":
        "Optimera dina affärsprocesser för maximal effektivitet",
      "check-box-text2": "Förbättra din lönsamhet med våra tillväxtlösningar",
      "check-box-text3":
        "Anpassade strategier skräddarsydda för dina unika affärsbehov",
      "growth-title": "Uppnå din fulla digitala potential",
      "growth-description":
        "Vi hjälper er med att utveckla eran verksamhet digitalt, ta kontakt med oss idag och låt oss växa tillsammans.",
      "contact-button-text": "Kom igång idag!",
      ourServices2: "Tidigare projekt",
      "service11-title": "Jordnära",
      "service12-title": "Målmedvetet Lean",
      "service13-title": "Strategidrivet",
      "service14-title": "Space-Age Tech",
      "service11-description":
        "Vi värdesätter rak kommunikation utan nonsens och strävar efter att främja en miljö av öppet och produktivt samarbete.",
      "service12-description":
        "Vi är ett smidig team. Vi är selektiva med våra kunder och vi investerar personligen i varje projekt som vi tar oss an.",
      "service13-description":
        "Vi fattar strategiska, datadrivna design- och innehållsbeslut för att säkerställa att din lösning ger en faktisk avkastning på investeringen.",
      "service14-description":
        "Vi bygger responsiva och tillgängliga lösningar på en snabb, säker och lättanvänd plattform, och vi tillhandahåller stensäker hosting och support.",
      servicesDescription2: "Titta närmare på några av våra tidigare arbeten",
      WebPage1: "Webbutveckling",
      WebPage2:
        "Vi utvecklar moderna webbplatser som passar just era behov och önskemål.",
      Kontakt: "Kontakt",
      Skapande: "Skapande",
      Lansering: "Lansering",
      Kontakt2:
        "Inled processen genom att kontakta oss. Vi tar oss tid att förstå din vision, diskutera dina mål och krav för att säkerställa en solid grund för projektet.",
      Skapande2:
        "Vårt engagerade team får arbeta, designa och utveckla en unik lösning som kombinerar visuell tilltalande med effektiv funktionalitet, skräddarsydd specifikt för dina behov.",
      Lansering2:
        "Efter noggranna tester och finesser lanserar vi ditt projekt. Men vår resa slutar inte här. Vi finns alltid här för stöd och för att säkerställa fortsatt framgång.",
      "Step1-title": "Så här arbetar vi",
      "Step1-description":
        "Det första steget i vår process handlar om den initiala kontakten med dig som kund. Under detta skede ägnar vi oss åt att förstå dina unika behov och önskemål. Vi diskuterar vad du vill uppnå med din hemsida, vilken typ av design och funktionalitet du efterfrågar, din tillgängliga budget samt en tidsplan för projektet. Detta möjliggör en smidig planering och säkerställer att vi är på samma sida från början. Vårt mål är att skapa en hemsida som speglar din vision och uppfyller alla dina krav.",
      "Step2-description":
        "Under detta skede går vi över till själva konstruktionen av din hemsida. Vad som särskiljer oss är vår iterativa arbetsmetod. Detta innebär att vi arbetar stegvis och kontinuerligt förfinar hemsidan baserat på feedback, så att du får en produkt som är anpassad efter dina behov. Vi tror starkt på öppen kommunikation och transparens. Därför kommer vi att hålla tätt samarbete och ständig kontakt med dig under hela skapandeprocessen. På så sätt kan du känna dig involverad, ge dina synpunkter och försäkra dig om att den färdiga hemsidan blir precis som du föreställt dig.",
      "Step3-description":
        "När det är dags för lansering av din hemsida är vår gemensamma resa långt ifrån över. Lanseringsprocessen är ett kritiskt skede, och vi prioriterar att ha dig involverad i varje steg. Innan vi officiellt lanserar sidan ser vi till att genomgå allt tillsammans, för att försäkra oss om att allt ser ut och fungerar som det ska. Med din feedback ser vi till att göra de sista justeringarna och finjusteringarna. När allt känns rätt och du är redo, hjälper vi till med den officiella lanseringen av din hemsida. Under hela denna process är transparens och kommunikation nyckelord. Vi ser till att du känner dig trygg, informerad och nöjd med det slutgiltiga resultatet.",
      "Website-showcase": "Från <0>Vision</0> till <1>Verklighet</1>",
      "Sample-title": "Exempel",
      "Showcase-description":
        "Vi skapar stilrena, moderna och dynamiska sidor som representerar erat företag på bästa sätt!",
      FAQTitle: "Vanliga frågor",
      faqQuestion1: "Vilka typer av hemsidor bygger ni?",
      faqQuestion2: "Hur lång tid tar det att utveckla en hemsida?",
      faqQuestion3: "Är era hemsidor mobilresponsiva?",
      faqQuestion4: "Vad kostar en hemsida?",
      faqQuestion5: "Erbjuder ni support och underhåll efter lansering?",
      faqQuestion6: "Kan jag uppdatera innehållet på min hemsida själv?",
      faqAnswer1:
        "Vi specialiserar oss på att skapa en mångfald av hemsidor för att tillgodose olika affärsbehov, från e-handelsplattformar och portföljsidor till bloggar och företagswebbplatser. Vårt team har erfarenhet inom flera branscher, vilket säkerställer att din hemsida skräddarsys för din specifika publik och mål.",
      faqAnswer2:
        "Utvecklingstiden varierar beroende på hemsidans komplexitet och funktioner. I genomsnitt tar en grundläggande hemsida mellan 2-4 veckor, medan mer komplexa sidor med skräddarsydda funktioner kan ta flera månader. Vi ger alltid en tidsuppskattning efter vår inledande konsultation.",
      faqAnswer3:
        "Absolut! Alla de hemsidor vi utvecklar är helt responsiva, vilket säkerställer att de ser ut och fungerar perfekt på smartphones, surfplattor och datorer. Med den ökande betydelsen av mobilt surfande prioriterar vi mobilvänlig design i alla våra projekt.",
      faqAnswer4:
        "Kostnaden för en hemsida beror på olika faktorer som dess komplexitet, designkrav och ytterligare funktioner. Vi erbjuder olika paket för att passa olika budgetar och behov. Efter att ha förstått dina krav kommer vi att ge ett transparent prisförslag utan dolda avgifter.",
      faqAnswer5:
        "Ja, det gör vi. Vi förstår att en hemsida inte är ett engångsprojekt utan en dynamisk plattform som behöver regelbundna uppdateringar och underhåll. Vi erbjuder olika support- och underhållspaket för att säkerställa att din hemsida förblir uppdaterad, säker och funktionell.",
      faqAnswer6:
        "Det beror på hemsidans uppbyggnad och plattform. Vissa av våra hemsidor byggs på användarvänliga plattformar som tillåter innehållsuppdateringar, medan andra kanske kräver teknisk expertis. Efter lanseringen kommer vi att klargöra detta och, vid behov, erbjuda utbildningssessioner för att guida dig.",
      NewSectionTitle: "Låter det här intressant?",
      NewSectionDescription:
        "<p>Om du är intresserad av att utforska vad vår tjänst kan erbjuda, tveka inte att höra av dig. Vi är engagerade i att säkerställa att våra lösningar passar dina behov perfekt. Kontakta oss idag för en <0>kostnadsfri</0> konsultation, så diskuterar vi hur vi bäst kan hjälpa dig.</p>",
      Maintenance: "Service & Underhåll",
      "Step4-description":
        "Underhåll är en avgörande fas som följer efter lansering av din webbplats. I det här steget ser vi till att din webbplats förblir uppdaterad, funktionell och säker. Regelbundna kontroller, prestandaoptimeringar och funktionsuppdateringar utförs för att hålla din webbplats på topp. Vi åtgärdar eventuella problem eller problem omedelbart, vilket säkerställer att dina användare får en sömlös upplevelse. Det är vårt åtagande att inte bara leverera utan också att underhålla och vårda din webbplats, vilket garanterar dess livslängd och din fortsatta tillfredsställelse.",
      ButtonText: "Kontakta Oss",
      omoss1: "Om Oss",
      omoss2:
        "Välkommen till Saigah. Vi kombinerar tradition med innovation för att alltid leverera tjänster av högsta kvalitet. Vår passion: dina behov i centrum.",
      whoAreWeDescription1:
        "I hjärtat av vårt företag ligger innovation. Varje dag utmanar vi status quo, skjuter på gränserna för vad som är möjligt. Vår åtagande att omdefiniera standarder har alltid varit vår drivkraft, vilket särskiljer oss på en trång marknad.",
      whoAreWeDescription2:
        "Vi levererar inte bara projekt; vi skapar värde. Varje mjukvaruprodukt, varje strategi och varje design är noggrant utformad för att inte bara möta utan överträffa våra kunders förväntningar. Vår ultimata belöning? Att bevittna våra kunder frodas i sina branscher med våra lösningar vid deras sida.",
      whoAreWeDescription3:
        "Passion är bränslet som driver oss. Oavsett om det är en mindre uppgift eller ett stort projekt, förblir vår hängivenhet oförändrad. Varje medlem av vårt team lägger sitt hjärta i sitt arbete, vilket säkerställer att det vi levererar är inget mindre än utmärkt.",
      whoAreWeTitle: "Vilka är vi?",
      valueTitleInnovation: "Innovation",
      valueTitleQuality: "Kvalitet",
      valueTitleCollaboration: "Samarbete",
      valueDescriptionInnovation:
        "I en värld som ständigt förändras, värderar vi innovation och anpassningsförmåga som vår kärnprincip. Vi strävar efter att alltid vara ett steg före i teknologins frontlinje.",
      valueDescriptionQuality:
        "Kvalitet och excellens är inte bara mål, utan våra grundläggande arbetsprinciper. Varje projekt är en ny chans att sätta vår kunskap och skicklighet på prov.",
      valueDescriptionCollaboration:
        "Vi tror att framgång bygger på starka relationer. Därför sätter vi alltid kundrelationer och samarbete i fokus, för att tillsammans skapa bestående värde.",
      valuesTitle: "Våra Värderingar",
      whatWeDoDescription1:
        "Vi erbjuder kundanpassade IT-lösningar för att driva innovation och affärsutveckling. Våra tjänster inkluderar allt från analys och design till anpassad mjukvaruutveckling, och vi använder agila metoder för att snabbt möta dina behov.",
      whatWeDoDescription2:
        "Varje projekt är en chans för oss att samarbeta med dig. Vi fokuserar på att förstå dina affärsutmaningar och mål, så att vi kan skräddarsy våra lösningar för att passa exakt.",
      whatWeDoDescription3:
        "Vi strävar efter att skapa långsiktigt värde, inte bara slutföra projekt. Med oss som partner får du mer än tekniska lösningar; du får ett engagerat team som vill se ditt företag lyckas.",
      whatWeDoTitle: "Vad Vi Gör",
      teamtitle: "Hjärnan bakom verksamheten",
      meetUsTitle: "Träffa oss",
      meetUsDescription:
        "Lär känna teamet bakom ditt nästa framgångsrika projekt. Boka ett möte med oss ​​idag för att diskutera dina behov och utforska de möjligheter som väntar dig.",
      meetUsButton: "Kontakta oss",
      "AI-tjanst1": "Förbättra din verksamhet med AI",
      "AI-tjanst2":
        "Boosta ditt företag med våra AI-verktyg för automatisering och optimering. Vi tar ert företag till nästa nivå.",
      "valuesTitle-AI": "Din nya AI partner",
      "valueTitleInnovation-AI": "Optimera",
      "valueTitleQuality-AI": "Automatisera",
      "valueDescriptionInnovation-AI":
        "Öka försäljning och stärk kundrelationer med AI som anpassar sig efter ditt bolags unika behov. Var alltid ett steg före konkurrenterna.",
      "valueDescriptionQuality-AI":
        "Automatisera tidskrävande processer och sänk dina driftskostnader. Fokusera istället på strategisk tillväxt och innovation.",
      "Step1-description-AI":
        "Vårt första steg tillsammans fokuserar på att förstå just ditt företags unika utmaningar och mål. Vi dyker djupt ner i vad du vill uppnå genom AI - oavsett om det handlar om att öka effektiviteten, förbättra kundupplevelsen, eller skära ner på kostnaderna. Vi tar hänsyn till din budget och tidslinje för att skräddarsy en lösning som är perfekt för dig. Genom att etablera en gemensam grund från början kan vi smidigt utforma en AI-strategi som inte bara möter, utan överträffar dina förväntningar.",
      "Step2-description-AI":
        "I denna fas övergår vi från planering till handling, med en arbetsmetodik som präglas av iteration och anpassning. Vi utvecklar AI-lösningar stegvis, för att kontinuerligt förbättra och justera baserat på din feedback. Genom en tät dialog och öppen kommunikation säkerställer vi att du är delaktig i varje steg av utvecklingsprocessen. På det sättet kan du vara trygg med att den slutliga AI-lösningen inte bara uppfyller men också överträffar förväntningarna.",
      "Step3-description-AI":
        "Lanseringen av din AI-lösning är inte slutet, det är början på en ny fas i vår relation. Denna viktiga milstolpe behandlas med yttersta omsorg. Vi går tillsammans igenom varje detalj av lösningen för att försäkra att allt fungerar felfritt. Din feedback är avgörande när vi gör de sista finjusteringarna. När du känner dig helt tillfreds sker den officiella lanseringen. Transparens och öppen kommunikation är ledord under hela processen, och vi säkerställer att du känner dig både trygg och nöjd med det slutliga resultatet.",
      "Step4-description-AI":
        "Underhåll är inte ett efterarbete, det är en del av vår långsiktiga strategi för din AI-lösning. Vi ser till att den inte bara är uppdaterad, men också optimerad och säker. Regelbundna prestandakontroller och nödvändiga uppdateringar garanterar att ditt system alltid fungerar på högsta nivå. Vid eventuella problem agerar vi omedelbart, för att garantera en konsekvent och effektiv användarupplevelse. Vår uppgift slutar inte vid leverans; vi är dedikerade till att långsiktigt vårda och underhålla din AI-lösning, så att den fortsätter att överträffa dina förväntningar.",
      "faqQuestion1-AI": "Hur fungerar AI-tjänsten och vad löser den?",
      "faqAnswer1-AI":
        "Vår AI-tjänst använder maskininlärning och andra avancerade algoritmer för att lösa specifika utmaningar som ditt företag kan stå inför, till exempel inom kundservice eller dataanalys. Tjänsten är utformad för att vara skalbar och kan anpassas för att möta ditt företags unika behov och mål.",
      "faqQuestion2-AI": "Är tjänsten säker och GDPR-kompatibel?",
      "faqAnswer2-AI":
        "Absolut, säkerhet och dataskydd är centrala för oss. Vår tjänst uppfyller alla krav enligt GDPR och använder moderna säkerhetsteknologier för att garantera att din data är säkert lagrad och hanterad.",
      "faqQuestion3-AI": "Hur integreras tjänsten med befintliga system?",
      "faqAnswer3-AI":
        "Vår tjänst är utvecklad för att smidigt kunna integreras i ditt nuvarande teknologiska ekosystem. Vi erbjuder robust detaljerad dokumentation och teknisk support för att underlätta integrationen.",
      "faqQuestion4-AI": "Hur användarvänlig är tjänsten?",
      "faqAnswer4-AI":
        "Vi har utvecklat vår tjänst med användarvänlighet i åtanke. En intuitiv användargränssnitt gör det enkelt att komma igång, och vi tillhandahåller även utförlig utbildning och support för att säkerställa en smidig användarupplevelse.",
      "faqQuestion5-AI": "Vilken teknisk kompetens krävs?",
      "faqAnswer5-AI":
        "Vår tjänst är designad för att vara så användarvänlig som möjligt. Vi tillhandahåller fullständig utbildning och teknisk support, så att du kan maximera värdet av tjänsten även utan fördjupad teknisk kunskap.",
      "faqQuestion6-AI": "Vad kostar det?",
      "faqAnswer6-AI":
        "Vi erbjuder flera prismodeller för att passa olika behov och budgetar. För en personlig och skräddarsydd kostnadsuppskattning, är du välkommen att kontakta vårt försäljningsteam.",
      Marketing_banner_text: "Vi driver din digitala marknadsföring ",
      Marketing_banner_text2:
        "Vi erbjuder resultatfokuserade lösningar som förstärker din synlighet och lönsamhet. Din tillväxt är vår prioritet.",
      marketingTitle: "Våra specialiteter ",
      MarketingTitle1: "SEO",
      MarketingTitle2: "SEM",
      MarketingTitle3: "SoMe",
      "faqQuestion1-AD": "Vad innebär er annonseringstjänst?",
      "faqAnswer1-AD":
        "Vår annonseringstjänst erbjuder företag möjligheten att marknadsföra sina produkter eller tjänster på olika plattformar, såsom sociala medier, sökmotorer och andra relevanta webbplatser, för att öka synligheten och nå en bredare målgrupp.",
      "faqQuestion2-AD": "Hur mycket kostar det att använda er tjänst?",
      "faqAnswer2-AD":
        "Kostnaden för vår annonseringstjänst varierar beroende på omfattningen och typen av kampanj. Vi erbjuder olika paket och anpassade lösningar för att möta specifika behov och budgetar. Kontakta oss gärna för en skräddarsydd offert.",
      "faqQuestion3-AD": "Hur mäter ni resultatet?",
      "faqAnswer3-AD":
        "Vi använder olika metoder och verktyg för att mäta resultatet av en kampanj, inklusive klickfrekvens (CTR), konverteringsgrad, visningar, och ROI (avkastning på investering). Vi tillhandahåller regelbundna rapporter som ger insikter om kampanjens prestanda och rekommendationer för förbättringar.",
      "faqQuestion4-AD": "Kan jag välja vilka plattformar?",
      "faqAnswer4-AD":
        "Ja, du har möjlighet att välja på vilka plattformar dina annonser ska visas. Vi kommer att rådge och rekommendera de mest effektiva plattformarna baserade på din målgrupp och dina affärsmål, men slutgiltiga beslutet ligger hos dig.",
      "faqQuestion5-AD": "Är det möjligt att ändra en kampanj?",
      "faqAnswer5-AD":
        "Ja, det är absolut möjligt att göra justeringar i en pågående kampanj. Vi övervakar ständigt kampanjens prestanda och kan snabbt göra nödvändiga ändringar för att optimera resultaten.",
      "faqQuestion6-AD": "Vilken typ av support erbjuder ni?",
      "faqAnswer6-AD":
        "Vi erbjuder full support under hela processen, från planering till genomförande och utvärdering av kampanjen. Vårt dedikerade team är tillgängligt via e-post, telefon och möten för att svara på frågor, lösa problem och diskutera strategier för att säkerställa att din kampanj är framgångsrik.",
      MarketingDescription1:
        "Öka organisk synlighet med vår SEO-expertis: exakt sökordsundersökning, optimering på sidan och strategiska bakåtlänkar. Förbättra rankningar, generera trafik och öka antalet omvandlingar.",
      MarketingDescription2:
        "Förbättra synligheten med vår SEM-expertis: effektiv PPC-hantering, från val av sökord till annonsdesign och budgetering. Kör kvalitetstrafik för överlägsen ROI.",
      MarketingDescription3:
        "Förstärk samhällsengagemang med våra SoMe-tjänster: övertygande innehåll och riktade annonser. Få kontakt med publiken, främja varumärkeslojalitet och öka engagemanget på alla plattformar.",
      "Kontakt-ad": "Konsultation & Strategi",
      "Skapande-ad": "Skapande & Utveckling",
      "Lansering-ad": "Lansering & Övervakning",
      "Maintenance-ad": "Utvärdering & Rapportering",
      "Step1-description-AD":
        "Under detta kritiska inledande skede genomför vi en omfattande diskussion med er för att i detalj förstå era affärsbehov, mål, förväntningar och budget. Efter en grundlig analys av er målgrupp, produkter/tjänster och marknad, utvecklar vi en anpassad och målinriktad annonseringsstrategi. Strategin inkluderar valet av de mest effektiva annonseringsplattformarna, en genomtänkt målgruppsegmentering, budgetallokering och en tydlig tidsplan. Denna fas lägger grunden för de följande stegen och säkerställer att alla parter har en klar och gemensam vision av kampanjen.",
      "Step2-description-AD":
        "I det här steget tar vårt kreativa team fram framstående och engagerande annonsinnehåll som inkluderar text, bilder, grafik och, vid behov, multimedieelement som video och animation. Eventuella landningssidor som krävs för kampanjen utvecklas också med fokus på användarvänlighet och konverteringsoptimering. Det skapade innehållet granskas noga för att säkerställa att det är i linje med ert varumärke och uppfyller kampanjens mål. Detta steg involverar ofta flera iterationer och justeringar baserat på er feedback för att säkerställa fullständig tillfredsställelse.",
      "Step3-description-AD":
        "När allt innehåll är godkänt och den övergripande strategin är på plats, lanseras er kampanj på de valda plattformarna. Efter lanseringen inleds en kontinuerlig övervakningsprocess där er kampanjs prestanda noga övervakas i realtid. Detta inkluderar övervakning av viktiga prestandaindikatorer som klickfrekvens, konverteringsfrekvens och ROI. Eventuella avvikelser eller möjligheter att förbättra kampanjens effektivitet identifieras snabbt och åtgärdas omgående.",
      "Step4-description-AD":
        "I detta avslutande steg genomförs en omfattande analys av er kampanjs övergripande prestanda och resultat. Detaljerade rapporter skapas som belyser de viktigaste framgångsfaktorerna och områdena för förbättring, och presenterar insikter om kampanjens avkastning på investering. Denna djupgående utvärdering ger både oss och er värdefull kunskap och förståelse som kan användas för att skärpa framtida annonseringsstrategier och initiativ. Ni får konkreta och mätbara resultat samt rekommendationer för fortsatta åtgärder och strategiska justeringar.",
      eager_to_learn1:
        "Fyll i formuläret nedan så kommer vi att kontakta dig så snart som möjligt",
      GDPR: "GDPR-policy",
      GDPR1: "Nedan kan du läsa om våran GDPR-policy.",
      Terms: "Användarvillkor",
      Terms1: "Nedan kan du läsa om våra användarvillkor",
      aboutPageTitle: "Om oss",
      aboutPageDescription:
        "Välkommen till Saigah. Vi kombinerar tradition med innovation för att alltid leverera tjänster av högsta kvalitet. Vår passion: dina behov i centrum.",
      contactPageTitle: "Kontakta oss",
      contactPageDescription:
        "Kontakta SAIGAH idag. Kontakta oss för kundsupport, förfrågningar om våra tjänster eller för att boka en konsultation. Vi finns här för att hjälpa dig med alla dina digitala lösningsbehov.",
      homePageTitle: "SAIGAH - Web solutions",
      homePageDescription:
        "Upptäck SAIGAHs utbud av digitala tjänster som är utformade för att förbättra ditt företags närvaro online. Från webbutveckling till AI-drivna analyser erbjuder vi avancerade lösningar anpassade till dina behov.",
      "Cookie-title": "Vi värdesätter din Integritet",
      "Cookie-desc":
        "Genom att klicka på Acceptera alla samtycker du till vår användning av cookies för bättre surfning\noch anpassat innehåll.",
      "Cookie-learn": "Läs mer",
      "Cookie-accept": "Acceptera alla",
      "Cookie-reject": "Neka alla",
      "footer-home": "Hem",
      "footer-website": "Webbutveckling",
      "footer-ai": "AI",
      "footer-adv": "Annonsering",
      "footer-about": "Om oss",
      "footer-contact": "Kontakt",
      "footer-terms": "Användarvillkor",
      "footer-contact-inf": "Kontakt information",
      "footer-service-inf": "Tjänster",
      "footer-phone": "Telefon: +46 727 202 106",
      Cookies: "Cookie policy",
      Cookies1: "Nedan har du möjlighet att läsa om våran Cookie policy.",
      bannerButtonText: "Kontakta oss",
      bannerButtonText2: "Våra tidigare projekt",
      aiChatbotvideo:
        "Våra kundtjänst chatbots är designade för att förändra din supportmetod. Genom att direkt engagera sig med kunderna upptäcker de specifika behov och ger skräddarsydda svar. De automatiserar rutinfrågor och gör att ditt team kan fokusera på komplexa problem, vilket ökar effektiviteten i din verksamhet. Dessa chatbotar är enkelt integrerade i dina tjänstesystem och spårar varje interaktion, hjälper till med skräddarsydda uppföljningar och ger starkare kundband.\n\nDessa smarta chatbots svarar inte bara på frågor, de vägleder aktivt kunderna och erbjuder rekommendationer och lösningar i realtid. De kan hantera en mängd olika frågor, från grundläggande vanliga frågor till svår felsökning, de säkerställer hög kundnöjdhet. Använd våra innovativa chatbots för att förbättra din kundservice och ligga i framkant av supportteknik.",
      "Customer-service-Chatbot": "Kundtjänst",
      "Onboarding-Chatbot": "Intern hjälp",
      "onboarding-chatbot-description":
        "Vår Interna Hjälp-Chatbot är utformad för att revolutionera din interna support. Genom att direkt interagera med medarbetarna identifierar den specifika behov och tillhandahåller anpassade lösningar. Denna AI-drivna assistent automatiserar standardfrågor, vilket gör att ditt team kan koncentrera sig på mer komplexa utmaningar och därigenom öka produktiviteten. Integrerad smidigt i dina interna system, spårar chatboten varje interaktion för effektiv uppföljning och förbättrar samarbetet inom företaget.\n\n Dessa intelligenta chatbots gör mer än att bara svara på frågor; de agerar som aktiva vägledare, erbjuder rekommendationer och lösningar i realtid. Kapabla att hantera allt från enkla förfrågningar till avancerad felsökning, säkerställer de hög nöjdhet och effektivitet i ditt företag. Implementera vår innovativa chatbot för att förbättra din interna service och ligga i framkant med arbetsplatsens supportteknologi.",
      WhyChatbot: "Varför välja AI-drivna chatbots?",
      WhyChatbot1:
        "Vi bygger dina AI-chatbotar: förbättra effektiviteten, anpassa interaktioner och höja prestanda i kund- och teamengagemang.",
      "Argument1-text":
        "Med våra chatbots erbjuds dina kunder eller intern personal svar på förfrågningar/frågor när som helst på dygnet. Detta kan vara avgörande i dagens värld där särskilt kunder förväntar sig snabba interaktioner. Och till skillnad från mänskliga operatörer behöver chatbots inte pauser, vilket säkerställer konsekvent service när som helst.",
      "Argument1-title": "Tillgänglighet",
      "Argument2-title": "Kostnadseffektivitet",
      "Argument2-text":
        "Genom att implementera chatbots kan man märkvärdigt reducera operativa kostnader genom exemeplvis reducerad handläggningstid. Dessa chatbots kan hantera hög volym av interaktioner samtidigt, vilket gör att erat team kan fokusera på de mer komplexa frågor. På så sätt optimeras arbetsflödet i eran verksamhet.",
      "Argument3-text":
        "Till skillnad ifrån andra chatbots så är våra chatbots designade för att erbjuda en personlig och engagerande kundupplevelse. De kan hantera en mängd olika frågor, från enkla vanliga frågor till mer komplexa frågor. Chatbotarna använder naturlig språkbehandling (NLP) som får dem att förstå och svara på ett konversationssätt, vilket gör interaktioner mer naturliga och familjära.",
      "Argument3-title": "Kundupplevelse",
      "Argument4-title": "Strukturerat",
      "Argument4-text":
        "Med våra chatbots så får hela eran verksamhet ett enkelt sätt att hitta data och information. På så sätt slipper ni slösa tid leta bland massor av dokument för att hitta den information ni söker, som då sparar tid och pengar. Datan ni väljer att lägga upp hanteras samtidigt med senaste säkerhetstandard för att säkra dess sekretess.",
      "valuesTitle-AI1": "Skräddarsydda AI chatbots",
      News_banner_text: "Nyheter",
      News_banner_text2: "Här publicerar vi våra artiklar.",
      "footer-news": "Nyheter",
      Article_banner_text: "Artikel",
      Article_banner_text2: "Nedan kan du läsa vald artikel.",

      insikter: "Våra senaste nyheter",

      latestPostsTitle: "Våra senaste insikter",
      Article_related_text: "Relaterade artiklar",
      "404_banner_text": "404",
      "404_banner_text2":
        "Du verkar ha hamnat lite snett! Sidan du söker tycks ha smitit undan. Återvänd till startsidan, eller tveka inte att kontakta vår support om du behöver vägledning för att hitta rätt.",
      "valueTitleFast-website": "Prestanda",
      "valueDescriptionFast-website":
        "Våra hemsidor erbjuder topprestanda med snabba laddningstider och en smidig användarupplevelse, optimerade för alla enheter.",
      "valueTitleSecure-website": "Trygg hosting",
      "valueDescriptionSecure-website":
        "Vår hosting erbjuder trygghet med stark säkerhet, hög tillgänglighet och pålitlig, snabb prestanda, optimerad för alla dina behov.",
      Information1: "Din nästa partner i Sundsvall",
      Information2:
        "Om du har några frågor om våra tjänster eller undrar hur vi kan bistå dig, tveka inte att höra av dig. Du är välkommen att begära en gratis offert för att få en uppfattning om våra priser.<br/><br/> Du kan kontakta oss genom att skicka ett e-postmeddelande eller genom att fylla i vårt kontaktformulär. Vi ser fram emot att snart få höra från dig och hoppas få bli din föredragna webbyrå i Sundsvall!",
      "Webbyrå Sundsvall": "Webbyrå Sundsvall",
      webagency:
        "Vi är en webbyrå i Sundsvall som erbjuder omfattande tjänster inom <1>webbdesign</1>, <3>digital marknadsföring</3>, <5>sökmotoroptimering/SEO</5> samt <7>AI</7>. Vårt mål är att stärka din närvaro online, öka trafiken till din hemsida och hjälpa dig att attrahera fler kunder. Vi ser fram emot möjligheten att diskutera hur vi kan förbättra din synlighet på nätet. Trots att vi är baserade i Sundsvall, erbjuder vi våra tjänster digitalt till kunder över hela Sverige.",
      servicestitle1: "Webbutveckling",
      servicestitle2: "SEO",
      servicestitle3: "Marknadsföring",
      servicestitle4: "AI",
      servicesdesc1:
        "Vi utvecklar moderna digitala lösningar inom webbplatser, e-handel, appar och intranät.",
      servicesdesc2:
        "Våra effektiva strategier ökar synligheten online och främjar organisk trafik genom SEO.",
      servicesdesc3:
        "Vi skapar kampanjer som engagerar just din målgrupp som ökar konverteringar.",
      servicesdesc4:
        "Våra AI-lösningar automatiserar processer och optimerar verksamheten för bättre resultat.",
      ourServices1: "Våra tjänster",
      whyus: "Varför välja oss?",
      whyusdescription:
        "Din framgång är vår framgång, och vi strävar efter att bygga varaktiga partnerskap baserade på förtroende och ömsesidig tillväxt.",
      whyus1: "Erfarenhet",
      whyus2: "Engagemang",

      whyusdescription1:
        "Vi har erfarenhet av webbutveckling, digital marknadsföring och AI, och vi använder vår expertis för att leverera lösningar efter dina behov.",

      whyusdescription2:
        "Vi är engagerade i din framgång och arbetar nära dig för att förstå dina mål, så att våra lösningar uppfyller dina förväntningar.",
      readTime: "min lästid",
      published: "Publicerad",
      category: "Kategori",
      previous: "« föregående",
      next: "nästa »",
      searchPlaceholder: "Sök artiklar...",
      newestFirst: "Nyast först",
      oldestFirst: "Äldst först",
      allCategories: "Alla kategorier",
      "Webbyrå Stockholm": "Webbyrå Stockholm",
      WebbyråStockholmDescription:
        "Vi är en webbyrå i Stockholm som fokuserar på webbutveckling, SEO, digital marknadsföring och AI. Låt oss hjälpa dig att nå dina digitala behov.",
      "steps.0.title": "Kontakt",
      "steps.0.content":
        "Det första steget i vår process handlar om den initiala kontakten. Vi lyssnar på dina behov och diskuterar hur vi kan hjälpa dig. Vi samlar in all nödvändig information för att förstå dina mål och förväntningar och sätta upp en plan för att gå vidare.",
      "steps.1.title": "Skapande",
      "steps.1.content":
        "I detta steg skapar vi en skräddarsydd lösning som passar just era behov. Vi tar hänsyn till din vision och mål för att skapa en unik design och funktionalitet som passar dig perfekt. Här jobbar vi iterativt med er för att säkerställa att ni är nöjda med resultatet.",
      "steps.2.title": "Lansering",
      "steps.2.content":
        "När allt är klart och godkänt är det dags att lansera lösningen som vi har skapar åt er. Vi ser till att allt fungerar som det ska och att ni är nöjda med resultatet. Vi stöttar er genom hela processen och ser till att ni är redo att ta nästa steg med lösningen.",
      "steps.3.title": "Underhåll",
      "steps.3.content":
        "Efter lanseringen fortsätter vi att stötta er med underhåll och support så länge ni har behovet. Vi ser till att er lösning är uppdaterad och fungerar som den ska. Vi finns alltid tillgängliga för att svara på frågor och hjälpa er med eventuella problem som kan uppstå.",
      timelinetitel: "Våran process",
      Information11: "Din nästa partner i Stockholm",
      Information21:
        "Om du har några frågor om våra tjänster eller undrar hur vi kan bistå dig, tveka inte att höra av dig. Du är välkommen att begära en gratis offert för att få en uppfattning om våra priser.<br/><br/> Du kan kontakta oss genom att skicka ett e-postmeddelande eller genom att fylla i vårt kontaktformulär. Vi ser fram emot att snart få höra från dig och hoppas få bli din föredragna webbyrå i Stockholm!",
      "Footer-texten":
        "Webblösningar designade för att driva din affärstillväxt.",
      "footer-gdpr": "Hantering av personuppgifter",
      learnMore: "Läs mer",
      project1Title: "E-commerce hemsida",
      project1Category: "Webb/UX",
      project1Description:
        "Vi har utvecklat en modern och användarvänlig e-handelsplattform för Fåtölj Experten. Webbplatsen erbjuder ett brett utbud av högkvalitativa fåtöljer, enkel navigering och en smidig shoppingupplevelse. Med sin responsiva design och säkra betalningslösningar som Klarna, Visa, Mastercard och PayPal, kan kunderna enkelt hitta och köpa sina drömfåtöljer från både dator och mobil.",
      project2Title: "AI chatbot för personalen",
      project2Category: "AI/Maskinlärning",
      project2Description:
        "Vi har utvecklat en modern och användarvänlig AI-driven intern chatbot för Andersson, en ledande producent av stålrör med över 50 års erfarenhet inom industrin. Chatbotten är utformad för att effektivisera hanteringen av interna frågor och förenkla arbetsprocesser.",
      project3Title: "Företags hemsida",
      project3Category: "Webb/UX",
      project3Description:
        "Road Cargo, en ledande leverantör av transportlösningar inklusive vägtransporter, logistik och spedition, har nu en modern och användarvänlig webbplats. Vi har utvecklat denna webbplats för att enkelt och överskådligt visa information om tjänster, kampanjer och kundrecensioner, vilket gör det enkelt för både besökare och administratörer att navigera.",

      website_description:
        "Helhetslösningar inom webbutveckling, design och underhåll.",
      seo_description: "Förbättra din verksamhet med AI.",
      marketing_description:
        "Marknadsföringstjänster för att öka din digitala närvaro.",
      botCase_banner_text: "AI chatbot för personalen",
      Case_banner_text2:
        "Anderssons AI-chatbot som Innovation för Intern Automation och Produktivitet",
      botCase_textHeader: "Intern effektivisering",
      botCase_textHeader2: "Lösning",
      botCase_textHeader3: "Resultat",
      botCase_paragraph:
        "Andersson är en framstående producent av stålrör med över 50 års erfarenhet inom industrin. Företaget är känt för sin höga kvalitet, innovation och förmåga att leverera produkter till en global marknad. Andersson strävar ständigt efter att förbättra sina produktionsprocesser och upprätthålla sin position som en ledande aktör inom branschen. För att fortsätta ligga i framkant och optimera sin interna effektivitet, beslutade Andersson att implementera en AI-driven lösning.",
      botCase_paragraph2:
        "Vi har utvecklat en intern AI-chatbot specifikt för Andersson med målet att effektivisera hanteringen av interna frågor, förenkla arbetsprocesser och få in en första fot i automatisering generellt. ",
      botCase_paragraph3:
        "För att optimera interna arbetsprocesser och förbättra tillgången till information hos Andersson, utvecklade vi en avancerad AI-chatbot genom att kombinera Voiceflow och GPT API.",
      botCase_paragraph4:
        "Voiceflow användes för att designa och bygga chatbotten. Vi kartlagde vanliga frågor och skapade detaljerade dialogträd för att simulera naturliga konversationer. Plattformen möjliggjorde både röst- och textinput, vilket gav användarna flexibilitet. Genom omfattande användartester kunde vi iterera och förbättra chatbotten baserat på feedback.",
      botCase_paragraph5:
        "GPT API från OpenAI integrerades sömlöst med Voiceflow för att ge chatbotten naturlig språkförståelse och svarsgenerering. Vi finjusterade GPT-modellen med skräddarsydd data för att hantera komplexa tekniska frågor inom Anderssons arbetsprocesser.",
      botCase_paragraph6:
        "Med Anderssons nya chatbot har medarbetarna tillgång till en omedelbar kunskapskälla som ersätter behovet av att söka råd från mer erfarna kollegor. Chatbotten kan snabbt ge svar på tekniska frågor, vägleda genom komplicerade processer och erbjuda lösningar på vanligt förekommande problem. Till exempel, om en anställd har en fråga om en specifik tillverkningsprocess och en mer erfaren kollega inte är tillgänglig, kan chatbotten erbjuda omedelbara och korrekta svar.",
      botCase_paragraph7:
        "Detta minskar väntetiderna och gör att arbetet kan fortgå utan blockers. Den förbättrade tillgången till information säkerställer att anställda kan arbeta helt självständigt och effektivt, samt numera underlättar onboardings-processer för nyanställda.",
      testimonial_text:
        "Saigah har visat en imponerande förståelse för våra behov och utmaningar. Deras innovativa och professionella arbete med vår AI-chatbot har överträffat våra förväntningar. Saigahs kreativa lösningar och proaktiva tillvägagångssätt har varit ovärderliga för oss genom hela projektet.",
      testimonial_author: "Andersson, Styrelsemedlem",
      Case_banner_text: "E-commerce hemsida",
      header_needs: "Efterfrågan",
      paragraph_1_needs:
        "Fåtölj Expertens tidigare hemsida var svårnavigerad och kändes omodern. Det fanns en stort efterfrågan av att öka användarvänligheten för både besökare och administratörer.",
      paragraph_2_needs:
        "Fåtölj Expertens främsta efterfrågan låg i att enkelt och överskådligt kunna visa information om produkter, kampanjer och kundrecensioner, samt att underlätta navigeringen för både kunder och administratörer. Fåtölj Experten erbjuder ett brett sortiment av sittmöbler, inklusive fåtöljer, soffor och stolar. Webbplatsen är också tänkt att fungera som en central plats för kunder som vill läsa bloggar om inredning, få tips om möbelvård eller ta del av specialerbjudanden. Det fanns också ett stort behov av att tillgänglighetsanpassa webbplatsen gällande både språkval och textstorlek.",
      header_solution: "Lösning",
      paragraph_1_solution:
        "Saigah har utarbetat en helt ny design och utvecklat Fåtölj Expertens e-handelswebbplats i enlighet med önskemålen om en modern och användarvänlig sida.",
      paragraph_2_solution:
        "Den design som skapades i samråd med Fåtölj Experten är modern, ren och enkel, med en känsla av elegans och komfort i de varma och inbjudande färgvalen. Saigah utvecklade även en kraftfull produktdatabas som gör det enkelt för kunder att hitta och jämföra olika fåtöljer och sittmöbler. Fåtölj Expertens webbutik uppdaterades också för att öka funktionaliteten och göra den mer pedagogisk och användarvänlig.",
      paragraph_3_solution:
        "Då tillgänglighet är viktigt för Fåtölj Experten har Saigah även uppfyllt kraven kring språkval genom att sidan är översatt till flera olika språk, samt sett till att texten är justerbar storleksmässigt.",
      Case_banner_text3:
        "Fåtölj Experten's nya e-handelswebbplats fokuserar på elegans, bekvämlighet och inredningstips.",
      header_result: "Resultat",
      paragraph_1_result:
        "Efter implementeringen av den nya designen och de funktionella förbättringarna har Fåtölj Expertens webbplats upplevt en betydande ökning i både användarvänlighet och kundnöjdhet. Den moderna och rena designen har skapat en inbjudande och elegant atmosfär som uppskattas av besökare. Den nya produktdatabasen har gjort det enklare för kunder att hitta och jämföra olika sittmöbler, vilket har lett till en ökning i konverteringsgraden och försäljningen.",
      paragraph_2_result:
        "Den förbättrade navigeringen har minskat antalet avhopp från webbplatsen och ökat tiden som besökarna spenderar på att utforska produkter och innehåll. Bloggsektionen och tips om möbelvård har lockat fler besökare och engagerat dem längre på webbplatsen. Dessutom har specialerbjudanden och kampanjer blivit mer synliga och tillgängliga, vilket har resulterat i en ökning av kampanjförsäljningarna.",
      landingpage_text: "Landing-page hemsida",
      landingpage_text2:
        "Road Cargo's nya landing-page fokuserar på att representera deras varumärke.",
      landingpage_1_needs:
        "Road Cargo’s tidigare hemsida var svårnavigerad och kändes omodern. Det fanns en stor efterfrågan på att öka användarvänligheten för både besökare och administratörer.",
      landingpage_2_needs:
        "Road Cargo’s främsta efterfrågan låg i att enkelt och överskådligt kunna visa information om tjänster, kampanjer och kundrecensioner, samt att underlätta navigeringen för både kunder och administratörer. Road Cargo erbjuder ett brett sortiment av transportlösningar, inklusive vägtransporter, logistik och spedition. Webbplatsen är också tänkt att fungera som en central plats för kunder som vill information om dess effektiva transporthantering eller ta del av befintliga specialerbjudanden. Det fanns också ett stort behov av att tillgänglighetsanpassa webbplatsen.",
      landingpage_1_solution:
        "Den nya hemsidan för Road Cargo har fått en modern design och förbättrad användarvänlighet. En ren layout gör det nu enkelt för besökare att navigera och hitta information om deras tjänster. För att underlätta administrationen har webbplatsen nu ett användarvänligt CMS system som förenklar uppdatering av innehåll.",
      landingpage_2_solution:
        "Vi har också implementerat tydliga sektioner för tjänster, information och kundrecensioner, vilket gör det lättillgänglig för kunder att hitta. För att möta behoven hos en bredare publik så tillgänglighetsanpassades för att möta alla typer av användare.",
      landingpage_3_solution:
        "Den nya sidan fungerar också som en central plats för administration för deras effektiva transporthantering, vilket hjälper Road Cargo att bättre möta kundernas behov och stärka sin marknadsposition.",
      valuesTitle1: "Grundprinciper vi jobbar utifrån",
      valueTitleInnovation1: "Responsivt anpassade",
      valueTitleQuality1: "Prestanda",
      valueTitleInnovation2: "Säkerhet",
      valueTitleQuality2: "Sökoptimerad",
      valueTitleCollaboration2: "CMS",
      valueTitleCollaboration1: "Skalbart",
      valueTitleResponsive: "Mobil anpassade",
      valueResponsive1: "Passar alla enheter, alla skärmstorlekar",
      valueResponsive2: "Anpassad navigering på mobil",
      valueResponsive3: "Optimerad UX",
      valueInnovation1: "Våra sidor skapas med React, Javascript och CSS",
      valueInnovation2: "Minimal belastningstid",
      valueInnovation3: "Optimerad kod",
      valueInnovation11: "Skalbara lösningar med lätt till expansion",
      valueInnovation12: "Flexibla kostnader beroende på behov",
      valueInnovation13: "Behovsanpassade molntjänster",
      valueSecure1: "Trygg hosting med AWS",
      valueSecure2: "Ständiga uppdateringar",
      valueSecure3: "Regelbunden säkerhetskopiering av eran data",
      valueSearch1: "Nyckelordsoptimering på det ni vill synas på",
      valueSearch2: "Gör det enklare för kunderna att hitta er online",
      valueSearch3: "Hamna först i den organiska trafiken",
      info1left:
        "Webbdesign, front-end & back-end integration, app-utveckling och löpande support.",
      info1right:
        "Vi bygger heltäckande digitala lösningar som är anpassade efter våra kunders unika behov. Ett erbjudande av tjänster inom webbdesign, front-end och back-end integration, utveckling av appar och löpande support.<br /><br /> Vi hanterar back-end integration, inklusive databashantering, API-integrationer och säkerhetslösningar. Vi har erfarenhet av att utveckla robusta och skalbara webb-tjänster som är anpassade för att möta kraven hos växande företag.",
      info3text:
        "Har du något projekt på gång? <underline>Låt oss</underline> kika på det!",
      info3contactButtonText: "Kontakta oss",
      "Argument1-list1": "24/7 åtkomst till information",
      "Argument1-list2": "Snabb och konsekvent service",
      "Argument1-list3": "Minska väntetider",
      "Argument2-list1": "Minska operativa kostnader",
      "Argument2-list2": "Hantera hög volym av interaktioner",
      "Argument2-list3": "Optimera arbetsflödet",
      "Argument3-list1": "Personlig och engagerande kundupplevelse",
      "Argument3-list2": "Naturlig språkbehandling",
      "Argument3-list3": "Förbättrar era kunders nöjdhet",
      "Argument4-list1": "Enkelt att hitta data och information",
      "Argument4-list2": "Spara tid och pengar",
      "Argument4-list3": "Säkert och konfidentiellt",

      "chatbotDescription.point1":
        "Automatisera rutinfrågor och frigör ditt team för komplexa problem.",
      "chatbotDescription.point2":
        "Ger skräddarsydda svar och uppföljningar, vilket stärker kundrelationerna.",
      "chatbotDescription.point3":
        "Integreras enkelt i dina system och spårar varje interaktion.",
      "chatbotDescription.point4":
        "Erbjuder vägledning och lösningar i realtid, vilket ökar kundnöjdheten.",
      "chatbotDescription.point5":
        "Hanterar både vanliga frågor och avancerad felsökning.",
      "chatbotDescription.point6":
        "Ligg i framkant inom supportteknik med våra innovativa chatbots.",
      "chatbotDescription.customerService.point1":
        "Svarar på kundfrågor dygnet runtAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "chatbotDescription.customerService.point2":
        "Automatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "chatbotDescription.customerService.point3":
        "Förbättrar kundnöjdhetenAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "chatbotDescription.customerService.point4":
        "Ger skräddarsydda svarAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "chatbotDescription.customerService.point5":
        "Hanterar en mängd olika frågorAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "chatbotDescription.customerService.point6":
        "Vägleder kunderna aktivtAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupportAutomatiserar kundsupport",
      "explainAi.title": "Vi ser möjligheten<br />för din automatisering.",
      "explainAi.paragraph1":
        "Är du nyfiken på hur AI eller maskinlärning skulle kunna användas just för ditt företag? Har du redan en idé eller behöver du vägledning? Vi hjälper dig att hitta möjligheter på hur detta skulle kunna gynna din verksamhet.",
      "explainAi.paragraph2":
        "Givet dagens snabba utveckling är det inte längre en fråga <em><strong>om</strong></em> AI kan användas i din verksamhet, utan <em><strong>hur</strong></em>. Oavsett om du vill effektivisera dina processer, förbättra kundupplevelsen, eller skapa nya affärsmöjligheter, kan AI och maskinlärning vara nyckeln till att ta din verksamhet till nästa nivå.",
      "explainAi.paragraph3":
        "Hör av dig för en kostnadsfri konsultation, så kan vi tillsammans kika på hur AI kan göra nytta för just dig.",
      "explainAi.contactButton": "Kontakta oss",
      "explainAi.iconData": "Data",
      "explainAi.iconModeling": "Modellering",
      "explainAi.iconEvaluate": "Utvärdera",
      "chatbot.Customer-service-Chatbot": "Kundtjänst",
      "chatbot.customerService.title1": "Kundsupport 24/7",
      "chatbot.customerService.description1":
        "Missa aldrig en kundförfrågan. Vår chatbot ger support dygnet runt och säkerställer att dina kunder alltid får den hjälp de behöver.",
      "chatbot.customerService.title2": "Omedelbara Svar",
      "chatbot.customerService.description2":
        "Automatisera din kundservice med omedelbara svar. Chatboten kan hantera vanliga frågor, produktinformation och mer, vilket förbättrar effektiviteten.",
      "chatbot.customerService.title3": "Multi-Channel Support",
      "chatbot.customerService.description3":
        "Engagera dina kunder på deras föredragna kanaler, oavsett om det är genom livechatt, sociala medier eller meddelandeappar.",
      "chatbot.customerService.title4": "Personliga Interaktioner",
      "chatbot.customerService.description4":
        "Leverera skräddarsydda upplevelser genom att anpassa interaktioner baserat på kunddata, vilket gör varje konversation relevant och engagerande.",

      "chatbot.Onboarding-Chatbot": "Intern hjälp",
      "chatbot.onboarding.title1": "Guidad Onboarding",
      "chatbot.onboarding.description1":
        "Gör onboarding enkelt med steg-för-steg vägledning för nya användare. Chatboten leder användare genom installationsprocessen med lätthet.",
      "chatbot.onboarding.title2": "Interaktiva Tutorials",
      "chatbot.onboarding.description2":
        "Erbjud interaktiva tutorials som hjälper användare att förstå och utnyttja produktens funktioner effektivt från dag ett.",
      "chatbot.onboarding.title3": "Support i Realtid",
      "chatbot.onboarding.description3":
        "Erbjud support i realtid under onboarding, vilket säkerställer att användarna får hjälp när de behöver det som mest, utan förseningar.",
      "chatbot.onboarding.title4": "Insamling av Feedback",
      "chatbot.onboarding.description4":
        "Samla in användarfeedback under onboarding för att kontinuerligt förbättra processen och öka användarnöjdheten.",
    },
  },
};

const savedLanguage = Cookies.get("userLanguage") || "sv";

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage, // Set the initial language to the saved language
  fallbackLng: "sv", // Fallback to Swedish if the saved language is not available
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
