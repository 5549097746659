import React, { useState, useEffect } from "react";
import styles from "./about.module.css";
import Menu from "./menu.js";
import Footer from "./footer.js";
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import whoarewe2 from "./assets/images/whoarewe2.webp";
import { FaLightbulb, FaSearchDollar, FaHandshake } from "react-icons/fa";
import whatwedo2 from "./assets/images/whatwedo2.webp";
import team1 from "./assets/images/team-2.jpg";
import team2 from "./assets/images/6715-quadrado-menor.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("omoss1")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("omoss2")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function WhoAreWe() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["who-are-we-section"]}>
      <div className={styles["who-are-we-content"]}>
        <div className={styles["text-container"]} data-aos="fade-right">
          <div className={styles["title-container"]}>
            <div></div>
            <h2>{t("whatWeDoTitle")}</h2>{" "}
            {/*Bytt plats på sektioner för bättre struktur*/}
          </div>
          <p>{t("whatWeDoDescription1")}</p>{" "}
          {/*Bytt plats på sektioner för bättre struktur*/}
          <p>{t("whoAreWeDescription2")}</p>
          <p>{t("whoAreWeDescription3")}</p>
        </div>
        <div className={styles["image-container"]} data-aos="fade-left">
          <img
            src={whoarewe2}
            alt="Description of Image"
            className={styles["who-are-we-image"]}
          />
        </div>
      </div>
    </div>
  );
}

function OurValues() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["values-section"]}>
      <div className={styles["title-container"]}>
        <div></div>
        <h2 className={styles["values-title"]}>{t("valuesTitle")}</h2>
      </div>
      <div className={styles["values-container"]} data-aos="fade-up">
        <div className={styles["value-item"]}>
          <FaLightbulb size={50} color="#ffffff" />
          <h3>{t("valueTitleInnovation")}</h3>
          <p>{t("valueDescriptionInnovation")}</p>
        </div>
        <div className={styles["value-item"]}>
          <FaSearchDollar size={50} color="#ffffff" />
          <h3>{t("valueTitleQuality")}</h3>
          <p>{t("valueDescriptionQuality")}</p>
        </div>
        <div className={styles["value-item"]}>
          <FaHandshake size={50} color="#ffffff" />
          <h3>{t("valueTitleCollaboration")}</h3>
          <p>{t("valueDescriptionCollaboration")}</p>
        </div>
      </div>
    </div>
  );
}

function WhatWeDo() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["what-we-do-section"]}>
      <div className={styles["what-we-do-content"]}>
        <div className={styles["image-container"]} data-aos="fade-in">
          <img
            src={whatwedo2}
            alt="Description of Image"
            className={styles["what-we-do-image"]}
          />
        </div>

        <div className={styles["text-container"]} data-aos="fade-in">
          <div className={styles["title-container"]}>
            <div></div>
            <h2 className={styles["section-title"]}>
              {t("whoAreWeTitle")}
            </h2>{" "}
            {/*Bytt plats på sektioner för bättre struktur*/}
          </div>
          <p>{t("whoAreWeDescription1")}</p>{" "}
          {/*Bytt plats på sektioner för bättre struktur*/}
          <p>{t("whatWeDoDescription2")}</p>
          <p>{t("whatWeDoDescription3")}</p>
        </div>
      </div>
    </div>
  );
}

const MeetUsSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className={styles.meetUsSection}>
      <div className={styles.titleContainer}>
        <div></div>
        <div className={styles.titleWrapper}>
          <h2 className={styles.meetUsTitle}>{t("meetUsTitle")}</h2>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <p className={styles.meetUsDescription}>{t("meetUsDescription")}</p>
        <Link to="/contact">
          {" "}
          {/* Add the Link component with the desired path */}
          <button className={styles.meetUsButton}>{t("meetUsButton")}</button>
        </Link>
      </div>
    </section>
  );
};

function About() {
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <Menu />
      <Helmet>
        <link rel="canonical" href="https://saigah.com/about" />
        <title>Om oss</title>
        <meta
          name="description"
          content="Upptäck mer om SAIGAH och vår passion för att revolutionera digital närvaro. Lär känna vårt engagemang för innovation inom webblösningar, digital marknadsföring, och AI-chatbots. Vi bygger framtidens digitala lösningar med fokus på kundens framgång."
        />
        <meta
          name="keywords"
          content="Om SAIGAH, SAIGAH mål, SAIGAH historia, vårat team, IT experter"
        />
        <meta property="og:title" content="Om oss" />
        <meta
          property="og:description"
          content="Upptäck mer om SAIGAH och vår passion för att revolutionera digital närvaro. Lär känna vårt engagemang för innovation inom webblösningar, digital marknadsföring, och AI-chatbots. Vi bygger framtidens digitala lösningar med fokus på kundens framgång."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/about" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Om oss" />
        <meta
          name="twitter:description"
          content="Upptäck mer om SAIGAH och vår passion för att revolutionera digital närvaro. Lär känna vårt engagemang för innovation inom webblösningar, digital marknadsföring, och AI-chatbots. Vi bygger framtidens digitala lösningar med fokus på kundens framgång."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        {/* Add additional meta tags as needed */}
      </Helmet>
      <Banner />
      <WhoAreWe />
      <OurValues />
      <WhatWeDo />
      {/*<TeamSection/>*/}
      <MeetUsSection />
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default About;
