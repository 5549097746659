import styles from "./gdprpage.module.css";
import Menu from "./menu";
import Footer from "./footer";
import React, { useState, useEffect } from "react";
import { useTranslation, i18n } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("GDPR")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("GDPR1")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function GDPRPage() {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <Menu />
      <Helmet>
        <title>GDPR Policy - SAIGAH</title>
        <meta
          name="description"
          content="Lär dig hur SAIGAH följer GDPR för att skydda dig och din data genom att följa senaste GDPR."
        />
        <meta
          name="keywords"
          content="GDPR, Dataskydd, Integritetspolicy, Personuppgiftshantering"
        />

        <meta property="og:title" content="GDPR Policy - SAIGAH" />
        <meta
          property="og:description"
          content="Lär dig hur SAIGAH följer GDPR för att skydda dig och din data genom att följa senaste GDPR."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/gdprpage" />

        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="SAIGAH" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GDPR Policy - SAIGAH" />
        <meta
          name="twitter:description"
          content="Lär dig hur SAIGAH följer GDPR för att skydda dig och din data genom att följa senaste GDPR."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />

        <link rel="canonical" href="https://saigah.com/gdprpage" />

        {/* Assuming MyComponent is properly defined and used for setting language or other head tags */}

        <meta name="robots" content="index, follow" />

        <meta name="author" content="SAIGAH" />
        <meta name="publisher" content="SAIGAH" />
        <link rel="icon" href="https://saigah.com/favicon.ico" />

        <meta name="al:ios:url" content="app://saigah/gdprpage" />
        <meta name="al:android:url" content="app://saigah/gdprpage" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "GDPR Policy",
            description:
              "Den här sidan beskriver GDPR för SAIGAH, och beskriver användningen av personuppgifter.",
            url: "https://saigah.com/gdprpage",
            // Additional structured data relevant to the cookies page
          })}
        </script>
      </Helmet>
      <div className={styles.contentContainer}>
        <Banner />
        <div className={styles.gdprSection}>
          <h1>General Data Protection Regulation (GDPR) Compliance Policy</h1>
          <p>
            <strong>Effective Date:</strong> 2023-11-25
          </p>

          <h2>Introduction</h2>
          <p>
            SAIGAH takes your privacy seriously. We are committed to
            safeguarding your personal information and ensuring that your
            privacy is protected. This GDPR Compliance Policy explains how we
            collect, use, disclose, and protect your personal data as governed
            by the General Data Protection Regulation (EU) 2016/679 ("GDPR").
          </p>

          <h2>Scope</h2>
          <p>
            This Policy applies to all services provided by SAIGAH and covers
            all individuals whose personal data we collect and process.
          </p>

          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <ul>
            <li>Identifiers: Name, username, email address</li>
            <li>Professional or employment-related data</li>
            <li>Customer feedback and survey responses</li>
          </ul>

          <h3>Sensitive Data</h3>
          <p>
            We do not collect sensitive data unless specifically provided by you
            and consented for specific use.
          </p>

          <h3>Technical Data</h3>
          <ul>
            <li>IP address</li>
            <li>Browser information</li>
            <li>Log data</li>
            <li>Cookies</li>
            <li>Usage Data</li>
            <li>Web pages visited</li>
            <li>Interaction with services</li>
            <li>Time spent on the website</li>
          </ul>

          <h2>Lawful Basis for Data Processing</h2>
          <p>We will process your data only:</p>
          <ul>
            <li>With your explicit consent</li>
            <li>To fulfill a contract with you</li>
            <li>To comply with a legal obligation</li>
            <li>Where there is a legitimate interest to do so</li>
          </ul>

          <h2>How We Use Your Data</h2>
          <ul>
            <li>To provide and maintain our services</li>
            <li>To manage customer accounts</li>
            <li>To improve our website and services</li>
            <li>To process transactions</li>
            <li>
              To communicate news, special offers, and general information about
              goods and services unless opted out
            </li>
            <li>For internal record-keeping and compliance audits</li>
            <li>To comply with legal obligations</li>
          </ul>

          <h2>Data Storage and Security Measures</h2>
          <p>
            Your data will be stored on secure servers within the European
            Economic Area (EEA) and will be encrypted during transition and
            rest.
          </p>
          <p>We implement robust security measures, including:</p>
          <ul>
            <li>Data encryption</li>
            <li>Security protocols to prevent unauthorized access</li>
            <li>Regular audits of data storage systems</li>
          </ul>

          <h2>Data Retention Policy</h2>
          <p>
            We will retain your personal data only for as long as is necessary
            to fulfill the purposes for which it was collected.
          </p>

          <h2>International Transfers</h2>
          <p>
            If we transfer your data outside of the EEA, we will ensure that
            proper safeguards, as required under GDPR, are in place.
          </p>

          <h2>Your Rights Under GDPR</h2>
          <ul>
            <li>Right to access: You can request a copy of your data.</li>
            <li>
              Right to rectification: You can correct or complete your
              information.
            </li>
            <li>Right to erasure: You can request your data be deleted.</li>
            <li>
              Right to data portability: You can request transfer of your data
              to another organization.
            </li>
            <li>
              Right to object and restrict processing: You can object to or
              restrict the processing of your data.
            </li>
          </ul>

          <h2>Exercising Your Rights</h2>
          <p>
            To exercise any of your rights, contact our Data Protection Officer
            at info@SAIGAH.com.
          </p>

          <h2>Third-Party Links</h2>
          <p>
            Our website may or could include links to third-party websites. This
            Policy does not extend to external sites.
          </p>

          <h2>Updates to this Policy</h2>
          <p>
            This Policy may be updated periodically to reflect changes in our
            practices and services. Notification of any changes will be made
            available on this page.
          </p>

          <h2>Contact Information</h2>
          <p>
            If you have questions or comments about this Policy, please contact:
          </p>
          <p>
            <strong>Data Protection Officer</strong>
            <br />
            SAIGAH
            <br />
            Sundsvall, SE
            <br />
            info@SAIGAH.com
            <br />
            +46 727 202 106
          </p>
        </div>
        <Footer />
        <ScrollToTopButton />
      </div>
    </div>
  );
}

export default GDPRPage;
