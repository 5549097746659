import React, { useState, useEffect, useRef } from "react";
import Footer from "./footer";
import styles from "./website.module.css";
import Menu from "./menu.js";
import { useTranslation, Trans } from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Updated the import for Font Awesome 5
import samplephone1 from "./assets/images/iphone_mockup1.webp";
import samplephone2 from "./assets/images/iphone-mockup2.webp";
import samplephone3 from "./assets/images/iphone_mockup3.webp";
import samplemacbook1 from "./assets/images/macbook_mockup1.webp";
import samplemacbook2 from "./assets/images/macbook-mockup2.webp";
import samplemacbook3 from "./assets/images/macbook_mockup3.webp";
import steg1 from "./assets/images/steg1-kontakt.webp";
import steg2 from "./assets/images/steg2-skapande.webp";
import steg3 from "./assets/images/steg3-lansering.webp";
import steg4 from "./assets/images/steg4-service.webp";
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import { FaBoltLightning } from "react-icons/fa6";
import { FaHandshakeSimple } from "react-icons/fa6";
import {
  FaLightbulb,
  FaSearchDollar,
  FaHandshake,
  FaRocket,
  FaLock,
} from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CiMobile3 } from "react-icons/ci";
import { GiSpeedometer } from "react-icons/gi";
import { PiSteps } from "react-icons/pi";
import { IoLockClosedOutline } from "react-icons/io5";
import { RiSeoLine } from "react-icons/ri";

import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("WebPage1")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("WebPage2")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function Info1() {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <div className={styles["info1-container"]}>
      <div className={styles["info1-content"]}>
        <div className={styles["info1-left"]}>{t("info1left")}</div>
        <div className={styles["info1-right"]}>
          <Trans i18nKey="info1right">
            Vi bygger heltäckande digitala lösningar som är anpassade efter våra
            kunders unika behov. Ett erbjudande av tjänster inom webbdesign,
            front-end och back-end integration, utveckling av appar och löpande
            support.
            <br />
            <br />
            Vi hanterar back-end integration, inklusive databashantering,
            API-integrationer och säkerhetslösningar. Vi har erfarenhet av att
            utveckla robusta och skalbara webb-tjänster som är anpassade för att
            möta kraven hos växande företag.
          </Trans>
        </div>
      </div>
    </div>
  );
}

function Info2() {
  return <div className={styles["info2-container"]}></div>;
}

function OurValues() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["values-section"]}>
      <div className={styles["title-container"]}>
        <div></div>
        <h2 className={styles["values-title"]}>{t("valuesTitle1")}</h2>
      </div>
      <div className={styles["values-container"]}>
        <div className={styles["value-item"]}>
          <CiMobile3 size={50} color="#34495e" />
          <h3>{t("valueTitleResponsive")}</h3>
          <ul className={styles["value-list"]}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueResponsive1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueResponsive2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueResponsive3")}
            </li>
          </ul>
        </div>
        <div className={styles["value-item"]}>
          <GiSpeedometer size={50} color="#34495e" />
          <h3>{t("valueTitleQuality1")}</h3>
          <ul className={styles["value-list"]}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation3")}
            </li>
          </ul>
        </div>
        <div className={styles["value-item"]}>
          <PiSteps size={50} color="#34495e" />
          <h3>{t("valueTitleCollaboration1")}</h3>
          <ul className={styles["value-list"]}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation11")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation12")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueInnovation13")}
            </li>
          </ul>
        </div>
      </div>
      <div className={styles["values-container"]}>
        <div className={styles["value-item"]}>
          <IoLockClosedOutline size={50} color="#34495e" />
          <h3>{t("valueTitleInnovation2")}</h3>
          <ul className={styles["value-list"]}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSecure1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSecure2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSecure3")}
            </li>
          </ul>
        </div>
        <div className={styles["value-item"]}>
          <RiSeoLine size={50} color="#34495e" />
          <h3>{t("valueTitleQuality2")}</h3>
          <ul className={styles["value-list"]}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSearch1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSearch2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("valueSearch3")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function Info3() {
  const underlineRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Play animation only once
    });

    const handleAOSAnimation = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("Element is in view, triggering underline animation");

          // Add a delay before starting the underline animation
          setTimeout(() => {
            if (underlineRef.current) {
              underlineRef.current.classList.add(styles["animate-underline"]);
            }
          }, 800); // 2000 ms = 2 sekunder
        }
      });
    };

    const observer = new IntersectionObserver(handleAOSAnimation, {
      threshold: 0.1, // Trigger when 10% of the element is in view
    });

    const targetElement = document.querySelector("[data-aos='fade-up']");

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <div className={styles["info3-container"]}>
      <div className={styles["info3-content"]} data-aos="fade-up">
        <p className={styles["info3-text"]}>
          <Trans
            i18nKey="info3text"
            components={{
              underline: (
                <span ref={underlineRef} className={styles["underline"]}></span>
              ),
            }}
          />
        </p>
        <Link to="/contact" className={styles["info3-button"]}>
          {t("info3contactButtonText")}
        </Link>
      </div>
    </div>
  );
}

function ContactStep() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["contact-container2"]}>
      <div className={styles["contact-container"]}>
        <div className={styles["contact-title-div"]}>
          <h2 className={styles["contact-title"]}>{t("Step1-title")}</h2>
        </div>
        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg1}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>1</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Kontakt")}</h3>
            <p className={styles["description-text"]}>
              {t("Step1-description")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg2}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>2</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Skapande")}</h3>
            <p className={styles["description-text"]}>
              {t("Step2-description")}
            </p>
          </div>
        </div>

        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg3}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>3</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Lansering")}</h3>
            <p className={styles["description-text"]}>
              {t("Step3-description")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg4}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>4</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Maintenance")}</h3>
            <p className={styles["description-text"]}>
              {t("Step4-description")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function WebsiteShowcase() {
  const [selectedSiteIndex, setSelectedSiteIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const { t } = useTranslation();

  const sites = [
    {
      phone: samplephone1,
      computer: samplemacbook1,
      color: "#9999FF",
      title1: "Informativa hemsidor",
      description1:
        "Vi utvecklar stilrena och moderna E-handelssidor som effektivt representerar erat företag online, med fokus på både optimering av användarvänlighet och försäljning.",
    },
    {
      phone: samplephone2,
      computer: samplemacbook2,
      color: "black",
      title1: "E-commerce",
      description1:
        "Vi designar engagerande, professionella och intuitiva informativa hemsidor som perfekt speglar ert företags identitet och värden, säkerställer en stark första intryck för era besökare.",
    },
    {
      phone: samplephone3,
      computer: samplemacbook3,
      color: "#5733FF",
      title1: "Eventsidor",
      description1:
        "Vi utvecklar omfattande portaler som fungerar som centrala nav för information och tjänster, skräddarsydda för att möta era unika behov och förena användare med precis det de söker.",
    },
  ];

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setSelectedSiteIndex((prevIndex) => (prevIndex + 1) % sites.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isPaused, selectedSiteIndex]);

  const handleChange = (event) => {
    setIsPaused(true);
    setSelectedSiteIndex(Number(event.target.value));
  };

  return (
    <div className={styles["showcase-section"]}>
      <div className={styles["description-section"]}>
        <h3>{sites[selectedSiteIndex].title1}</h3>
      </div>

      <div className={styles["showcase-container"]}>
        <div className={styles["devices"]}>
          <img
            src={sites[selectedSiteIndex].phone}
            alt="Phone Mockup"
            className={styles["phone-mockup"]}
          />
          <img
            src={sites[selectedSiteIndex].computer}
            alt="Computer Mockup"
            className={styles["computer-mockup"]}
          />
        </div>

        <div className={styles["bottom-section"]}>
          <div className={styles["slider-container"]}>
            <input
              type="range"
              min="0"
              max="2"
              step="1"
              value={selectedSiteIndex}
              onChange={handleChange}
              className={styles["sample-slider"]}
              aria-label="Slider Label"
            />
            <div className={styles["slider-labels"]}>
              <span>I</span>
              <span>II</span>
              <span>III</span>
            </div>
            <button
              className={styles["pause-button"]}
              onClick={() => setIsPaused(!isPaused)}
              aria-label={isPaused ? "Play" : "Pause"}
            >
              {isPaused ? (
                <i className="fas fa-play"></i>
              ) : (
                <i className="fas fa-pause"></i>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function FAQSection() {
  const { t } = useTranslation();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const questions = [
    { q: t("faqQuestion1"), a: t("faqAnswer1") },
    { q: t("faqQuestion2"), a: t("faqAnswer2") },
    { q: t("faqQuestion3"), a: t("faqAnswer3") },
    { q: t("faqQuestion4"), a: t("faqAnswer4") },
    { q: t("faqQuestion5"), a: t("faqAnswer5") },
    { q: t("faqQuestion6"), a: t("faqAnswer6") },
  ];

  return (
    <div className={styles["faq-section"]}>
      <h2>{t("FAQTitle")}</h2>
      <div className={styles["faq-grid"]}>
        <div>
          {questions.slice(0, questions.length / 2).map((item, index) => (
            <div
              key={index}
              className={styles["faq-item"]}
              onClick={() =>
                setActiveQuestionIndex(
                  index === activeQuestionIndex ? null : index
                )
              }
            >
              <h3 className={styles["faq-question"]}>{item.q}</h3>
              <div className={styles["icon-indicator"]}>
                <i
                  className={`fas fa-chevron-${
                    index === activeQuestionIndex ? "up" : "down"
                  }`}
                ></i>
              </div>
              {index === activeQuestionIndex && (
                <div>
                  <p className={styles["faq-answer"]}>{item.a}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div>
          {questions.slice(questions.length / 2).map((item, index) => {
            // Adjusting index for the second half
            let adjustedIndex = index + Math.ceil(questions.length / 2);
            return (
              <div
                key={adjustedIndex}
                className={styles["faq-item"]}
                onClick={() =>
                  setActiveQuestionIndex(
                    adjustedIndex === activeQuestionIndex ? null : adjustedIndex
                  )
                }
              >
                <h3 className={styles["faq-question"]}>{item.q}</h3>
                <div className={styles["icon-indicator"]}>
                  <i
                    className={`fas fa-chevron-${
                      adjustedIndex === activeQuestionIndex ? "up" : "down"
                    }`}
                  ></i>
                </div>
                {adjustedIndex === activeQuestionIndex && (
                  <div>
                    <p className={styles["faq-answer"]}>{item.a}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function NewSection() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["new-section"]}>
      <div className={styles["content-section"]} data-aos="fade-up">
        <h2>{t("NewSectionTitle")}</h2>
        <Trans
          i18nKey="NewSectionDescription"
          components={[
            <span
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            />,
          ]}
        />
        <Link to="/contact">
          <button className={styles["content-section-button"]}>
            {t("ButtonText")}
          </button>
        </Link>
      </div>
    </div>
  );
}

const Website = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.contentContainer}>
      <Menu />
      <Helmet>
        <link rel="canonical" href="https://saigah.com/website" />
        <title>Vi skapar eran hemsida</title>
        <meta
          name="description"
          content="Vi hjälper er gå ifrån vision till färdig hemida! SAIGAH är experter på att skapa hemsidor med fokus på SEO/sökmotoroptimering."
        />
        <meta
          name="keywords"
          content="hemsida, web design, website development, responsive design, ecommerce solutions, web hosting, SEO, user experience, UX design, webbplatsdesign, webbutveckling, responsiv design, e-handelslösningar, webbhotell, sökmotoroptimering, användarupplevelse, UX-design"
        />
        <meta property="og:title" content="Vi skapar eran hemsida" />
        <meta
          property="og:description"
          content="Vi hjälper er gå ifrån vision till färdig hemida! SAIGAH är experter på att skapa hemsidor med fokus på SEO/sökmotoroptimering."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Vi skapar eran hemsida" />
        <meta
          name="twitter:description"
          content="Vi hjälper er gå ifrån vision till färdig hemida! SAIGAH är experter på att skapa hemsidor med fokus på SEO/sökmotoroptimering."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        {/* Add additional meta tags as needed */}
      </Helmet>
      <Banner />
      <Info1 />
      <WebsiteShowcase />
      <OurValues />
      <Info3 />

      <ContactStep />
      <FAQSection />
      <NewSection />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default Website;
