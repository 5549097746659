import React, { useEffect, useState, useRef } from "react";
import Footer from "./footer";
import { Link, useLocation } from "react-router-dom";
import styles from "./ai.module.css";
import Menu from "./menu.js";
import { useTranslation, Trans } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { BiDollar, BiRun } from "react-icons/bi";
import steg1 from "./assets/images/ai-bild1.webp";
import steg2 from "./assets/images/ai-bild2.webp";
import steg3 from "./assets/images/ai-bild3.webp";
import steg4 from "./assets/images/ai-bild4.webp";
import chatbotVideo from "./assets/videos/output.webm";
import chatbotVideo2 from "./assets/videos/output2.webm";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import { LuCalendarClock } from "react-icons/lu";
import { MdAttachMoney } from "react-icons/md";
import { TbFileDatabase } from "react-icons/tb";
import ScrollToTopButton from "./ScrollToTop.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  faFileAlt,
  faCogs,
  faChartLine,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { TbPigMoney } from "react-icons/tb";

import { RiCustomerService2Line } from "react-icons/ri";

import { ImFileText2 } from "react-icons/im";
import { PiChartLineUp } from "react-icons/pi";

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { PiSteps } from "react-icons/pi";
import { IoLockClosedOutline } from "react-icons/io5";
import { RiSeoLine } from "react-icons/ri";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { TfiFiles } from "react-icons/tfi";
import { CiFaceSmile } from "react-icons/ci";

import { RxClock } from "react-icons/rx";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("AI-tjanst1")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("AI-tjanst2")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function ExplainAi() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["explain-section"]}>
      <div className={styles["content-container"]} data-aos="fade-up">
        <div className={styles["text-container"]}>
          <h2 className={styles["explainTitle"]}>
            <Trans i18nKey="explainAi.title" />
          </h2>
          <p className={styles["explainText"]}>
            <Trans i18nKey="explainAi.paragraph1" />
          </p>
          <p className={styles["explainText"]}>
            <Trans
              i18nKey="explainAi.paragraph2"
              components={{
                strong: <strong />,
                em: <em />,
              }}
            />
          </p>
          <p className={styles["explainText"]}>
            <Trans i18nKey="explainAi.paragraph3" />
          </p>
          <Link to="/contact" className={styles.growthButton}>
            {t("explainAi.contactButton")}
          </Link>
        </div>
        <div className={styles["icons-container"]}>
          <div className={styles["icon-with-title"]}>
            <ImFileText2 className={styles.icon} />
            <span className={styles["icon-title"]}>
              {t("explainAi.iconData")}
            </span>
          </div>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
          <div className={styles["icon-with-title"]}>
            <FontAwesomeIcon icon={faCogs} className={styles.icon} />
            <span className={styles["icon-title"]}>
              {t("explainAi.iconModeling")}
            </span>
          </div>
          <FontAwesomeIcon icon={faArrowRight} className={styles.arrow} />
          <div className={styles["icon-with-title"]}>
            <PiChartLineUp className={styles.icon} />
            <span className={styles["icon-title"]}>
              {t("explainAi.iconEvaluate")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Info3() {
  const underlineRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Play animation only once
    });

    const handleAOSAnimation = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("Element is in view, triggering underline animation");

          // Add a delay before starting the underline animation
          if (underlineRef.current) {
            setTimeout(() => {
              underlineRef.current.classList.add(styles["animate-underline"]);
            }, 500); // 500 ms = 0.5 second (reduced delay)
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleAOSAnimation, {
      threshold: 0.1, // Trigger when 10% of the element is in view
    });

    const targetElement = underlineRef.current.closest("[data-aos='fade-up']");

    if (targetElement) {
      observer.observe(targetElement);
      console.log("Observer created and observing:", targetElement);
    } else {
      console.error("Target element not found for IntersectionObserver.");
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <div className={styles["info3-container"]}>
      <div className={styles["info3-content"]} data-aos="fade-up">
        <p className={styles["info3-text"]}>
          <Trans
            i18nKey="info3text"
            components={{
              underline: (
                <span ref={underlineRef} className={styles["underline"]}></span>
              ),
            }}
          />
        </p>
        <Link to="/contact" className={styles["info3-button"]}>
          {t("info3contactButtonText")}
        </Link>
      </div>
    </div>
  );
}

function OurValues() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["values-section"]}>
      <div className={styles["title-container"]}>
        <h2 className={styles["values-title"]} data-aos="fade-up">
          {t("WhyChatbot")}
        </h2>
      </div>
      <div className={styles["values-circle"]} data-aos="fade-up">
        <div
          className={`${styles["value-item"]} ${styles["item-1"]} ${styles["item-1-line"]} `}
        >
          <RxClock size={50} color="#34495e" />
          <h3>{t("Argument1-title")}</h3>
          <ul className={`${styles["value-list"]} ${styles["item-1-arrow"]}`}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument1-list1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument1-list2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument1-list3")}
            </li>
          </ul>
        </div>
        <div
          className={`${styles["value-item"]} ${styles["item-2"]} ${styles["item-2-line"]}`}
        >
          <TbPigMoney size={50} color="#34495e" />
          <h3>{t("Argument2-title")}</h3>
          <ul className={`${styles["value-list"]} ${styles["item-2-arrow"]}`}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument2-list1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument2-list2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument2-list3")}
            </li>
          </ul>
        </div>
        <div
          className={`${styles["value-item"]} ${styles["item-3"]} ${styles["item-3-line"]}`}
        >
          <TfiFiles size={50} color="#34495e" />
          <h3>{t("Argument4-title")}</h3>
          <ul className={`${styles["value-list"]} ${styles["item-3-arrow"]}`}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument4-list1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument4-list2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument4-list3")}
            </li>
          </ul>
        </div>
        <div
          className={`${styles["value-item"]} ${styles["item-4"]} ${styles["item-4-line"]}`}
        >
          <RiCustomerService2Line size={50} color="#34495e" />
          <h3>{t("Argument3-title")}</h3>
          <ul className={`${styles["value-list"]} ${styles["item-4-arrow"]}`}>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument3-list1")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument3-list2")}
            </li>
            <li>
              <IoIosCheckmarkCircleOutline
                className={styles["checkmark-icon"]}
              />{" "}
              {t("Argument3-list3")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

const chatbotData = [
  {
    name: "Customer-service-Chatbot", // Translation key for the name
    video: chatbotVideo,
    points: [
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.customerService.title1", // Translation key for the title
        description: "chatbot.customerService.description1", // Translation key for the description
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.customerService.title2",
        description: "chatbot.customerService.description2",
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.customerService.title3",
        description: "chatbot.customerService.description3",
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.customerService.title4",
        description: "chatbot.customerService.description4",
      },
    ],
  },
  {
    name: "Onboarding-Chatbot", // Translation key for the name
    video: chatbotVideo2,
    points: [
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.onboarding.title1", // Translation key for the title
        description: "chatbot.onboarding.description1", // Translation key for the description
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.onboarding.title2",
        description: "chatbot.onboarding.description2",
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.onboarding.title3",
        description: "chatbot.onboarding.description3",
      },
      {
        number: <IoIosCheckmarkCircleOutline />,
        title: "chatbot.onboarding.title4",
        description: "chatbot.onboarding.description4",
      },
    ],
  },
];

function AIShowcase() {
  const [selectedChatbotIndex, setSelectedChatbotIndex] = useState(0);
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  const handleChatbotChange = (index) => {
    if (index !== selectedChatbotIndex) {
      setSelectedChatbotIndex(index);
    }
  };

  const togglePlayVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        videoContainerRef.current.classList.add(styles["video-playing"]);
      } else {
        videoRef.current.pause();
        videoContainerRef.current.classList.remove(styles["video-playing"]);
      }
    }
  };

  const sliderStyle = {
    transform: `translateX(${selectedChatbotIndex * 100}%)`,
    transition: "transform 0.3s ease-in-out",
  };

  return (
    <div className={styles["showcase-section"]}>
      <div className={styles["block"]}>
        <div className={styles["video-container"]} ref={videoContainerRef}>
          <video
            ref={videoRef}
            key={chatbotData[selectedChatbotIndex].video}
            src={chatbotData[selectedChatbotIndex].video}
            className={styles["video"]}
            muted
            loop
            onClick={togglePlayVideo} // Clicking the video pauses it
          />
          <div
            className={styles["play-button"]}
            onClick={togglePlayVideo} // Clicking the play button starts the video
          >
            <i className="fa fa-play"></i>
          </div>
        </div>
        <div className={styles["content-container1"]}>
          <h2 className={styles["showcase-title"]}>{t("valuesTitle-AI1")}</h2>
          <div className={styles["button-container-wrapper"]}>
            <div className={styles["button-container"]}>
              <div className={styles["slider"]} style={sliderStyle}></div>
              {chatbotData.map((chatbot, index) => (
                <button
                  key={chatbot.name}
                  className={`${styles["button"]} ${
                    selectedChatbotIndex === index ? styles["active"] : ""
                  } ${index === 0 ? styles["firstButton"] : ""} ${
                    index === 1 ? styles["secondButton"] : ""
                  }`}
                  onClick={() => handleChatbotChange(index)}
                >
                  {t(`chatbot.${chatbot.name}`)}
                </button>
              ))}
            </div>
            <div className={styles["border-bottom"]}></div>
          </div>

          <div className={`${styles["description-container"]}`}>
            <ul className={styles["chatbot-description"]}>
              {chatbotData[selectedChatbotIndex].points.map((point, index) => (
                <li key={index}>
                  <div className={styles["point-number"]}>{point.number}</div>
                  <div className={styles["point-content"]}>
                    <div className={styles["point-title"]}>
                      {t(point.title)}
                    </div>
                    <div className={styles["point-description"]}>
                      {t(point.description)}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactStep() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["contact-container2"]}>
      <div className={styles["contact-container"]}>
        <div className={styles["contact-title-div"]}>
          <h2 className={styles["contact-title"]}>{t("Step1-title")}</h2>
        </div>
        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg1}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>1</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Kontakt")}</h3>
            <p className={styles["description-text"]}>
              {t("Step1-description-AI")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg2}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>2</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Skapande")}</h3>
            <p className={styles["description-text"]}>
              {t("Step2-description-AI")}
            </p>
          </div>
        </div>

        <div className={styles["step-wrapper"]} data-aos="fade-up">
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg3}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>3</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Lansering")}</h3>
            <p className={styles["description-text"]}>
              {t("Step3-description-AI")}
            </p>
          </div>
        </div>

        <div
          className={`${styles["step-wrapper"]} ${styles["reverse"]}`}
          data-aos="fade-up"
        >
          <div className={styles["icon-step-container"]}>
            <div className={styles["image-container"]}>
              <img
                src={steg4}
                alt="Handshake"
                className={styles["icon-image"]}
              />
            </div>
            <span className={styles["step-number"]}>4</span>
          </div>
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["smaller-title"]}>{t("Maintenance")}</h3>
            <p className={styles["description-text"]}>
              {t("Step4-description-AI")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewSection() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles["new-section"]}>
      <div className={styles["content-section"]} data-aos="fade-up">
        <h2>{t("NewSectionTitle")}</h2>
        <Trans
          i18nKey="NewSectionDescription"
          components={[
            <span
              style={{ textDecoration: "underline", fontWeight: "bold" }}
            />,
          ]}
        />
        <Link to="/contact">
          <button className={styles["content-section-button"]}>
            {t("ButtonText")}
          </button>
        </Link>
      </div>
    </div>
  );
}

function FAQSection() {
  const { t } = useTranslation();

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const questions = [
    { q: t("faqQuestion1-AI"), a: t("faqAnswer1-AI") },
    { q: t("faqQuestion2-AI"), a: t("faqAnswer2-AI") },
    { q: t("faqQuestion3-AI"), a: t("faqAnswer3-AI") },
    { q: t("faqQuestion4-AI"), a: t("faqAnswer4-AI") },
    { q: t("faqQuestion5-AI"), a: t("faqAnswer5-AI") },
    { q: t("faqQuestion6-AI"), a: t("faqAnswer6-AI") },
  ];

  return (
    <div className={styles["faq-section"]}>
      <h2>{t("FAQTitle")}</h2>
      <div className={styles["faq-grid"]}>
        <div>
          {questions.slice(0, questions.length / 2).map((item, index) => (
            <div
              key={index}
              className={styles["faq-item"]}
              onClick={() =>
                setActiveQuestionIndex(
                  index === activeQuestionIndex ? null : index
                )
              }
            >
              <h3 className={styles["faq-question"]}>{item.q}</h3>
              <div className={styles["icon-indicator"]}>
                <i
                  className={`fas fa-chevron-${
                    index === activeQuestionIndex ? "up" : "down"
                  }`}
                ></i>
              </div>
              {index === activeQuestionIndex && (
                <div>
                  <p className={styles["faq-answer"]}>{item.a}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div>
          {questions.slice(questions.length / 2).map((item, index) => {
            // Adjusting index for the second half
            let adjustedIndex = index + Math.ceil(questions.length / 2);
            return (
              <div
                key={adjustedIndex}
                className={styles["faq-item"]}
                onClick={() =>
                  setActiveQuestionIndex(
                    adjustedIndex === activeQuestionIndex ? null : adjustedIndex
                  )
                }
              >
                <h3 className={styles["faq-question"]}>{item.q}</h3>
                <div className={styles["icon-indicator"]}>
                  <i
                    className={`fas fa-chevron-${
                      adjustedIndex === activeQuestionIndex ? "up" : "down"
                    }`}
                  ></i>
                </div>
                {adjustedIndex === activeQuestionIndex && (
                  <div>
                    <p className={styles["faq-answer"]}>{item.a}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Ai = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.contentContainer}>
      <Menu />

      <Helmet>
        <link rel="canonical" href="https://saigah.com/ai" />
        <title>Förbättra din verksamhet med AI</title>
        <meta
          name="description"
          content="Vi bygger dina AI chatbots; förbättra effektiviteten, anpassa interaktioner och höj kund- och teamengagemang."
        />
        <meta
          name="keywords"
          content="chatbot, natural language processing, NLP, AI technology, predictive analytics, AI application, AI tools, chattbot, naturlig språkbehandling, NLP, AI-teknik, prediktiv analys, AI-applikation, AI-verktyg"
        />
        <meta property="og:title" content="Förbättra din verksamhet med AI" />
        <meta
          property="og:description"
          content="Vi bygger dina AI chatbots; förbättra effektiviteten, anpassa interaktioner och höj kund- och teamengagemang."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/ai" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Förbättra din verksamhet med AI" />
        <meta
          name="twitter:description"
          content="Vi bygger dina AI chatbots; förbättra effektiviteten, anpassa interaktioner och höj kund- och teamengagemang."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        {/* Add additional meta tags as needed */}
      </Helmet>
      <Banner />
      <ExplainAi />

      <AIShowcase />
      <OurValues />
      <Info3 />
      <ContactStep />
      <FAQSection />
      <NewSection />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default Ai;
