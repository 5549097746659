import React, { useState, useEffect } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import styles from "./languageselect.module.css";
import { useTranslation } from "react-i18next";
import i18next from "./i18n.js";

const LanguageSelector = () => {
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const toggleLanguageDropdown = (e) => {
    e.stopPropagation();
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleSelection = (lng) => {
    console.log("Language selected:", lng); // Debugging log
    i18next.changeLanguage(lng);
    setSelectedLanguage(lng);
    setIsLanguageDropdownOpen(false);
  };

  return (
    <div
      className={styles.languageOptions}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={styles.languageButton}
        onClick={toggleLanguageDropdown}
      >
        {selectedLanguage === "sv" ? "Svenska" : "English"}{" "}
        {isLanguageDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      {isLanguageDropdownOpen && (
        <ul
          className={`${styles.languageDropdown} ${
            isLanguageDropdownOpen ? styles.showLanguageDropdown : ""
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <li
            tabIndex="0"
            onClick={() => handleSelection("sv")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSelection("sv");
              }
            }}
          >
            Svenska
          </li>
          <li
            tabIndex="0"
            onClick={() => handleSelection("en")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSelection("en");
              }
            }}
          >
            English
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
