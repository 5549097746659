import styles from "./cookies.module.css";
import Menu from "./menu";
import Footer from "./footer";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowDown } from "react-icons/fa";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("Cookies")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("Cookies1")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function CookiesPage() {
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }
  return (
    <div>
      <Helmet>
        <title>Cookie Policy - SAIGAH</title>
        <meta
          name="description"
          content="Lär dig hur SAIGAH använder cookies för att förbättra din webbupplevelse, komma ihåg dina preferenser och förbättra prestandan på vår webbplats."
        />
        <meta
          name="keywords"
          content="cookie policy, cookies, website cookies, cookie consent, tracking, website tracking"
        />

        <meta property="og:title" content="Cookie Policy - SAIGAH" />
        <meta
          property="og:description"
          content="Lär dig hur SAIGAH använder cookies för att förbättra din webbupplevelse, komma ihåg dina preferenser och förbättra prestandan på vår webbplats."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/cookies" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="SAIGAH" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Cookie Policy - SAIGAH" />
        <meta
          name="twitter:description"
          content="Lär dig hur SAIGAH använder cookies för att förbättra din webbupplevelse, komma ihåg dina preferenser och förbättra prestandan på vår webbplats."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />

        <link rel="canonical" href="https://saigah.com/cookies" />

        {/* Assuming MyComponent is properly defined and used for setting language or other head tags */}

        <meta name="robots" content="index, follow" />

        <meta name="author" content="SAIGAH" />
        <meta name="publisher" content="SAIGAH" />
        <link rel="icon" href="https://saigah.com/favicon.ico" />

        <meta name="al:ios:url" content="app://saigah/cookies" />
        <meta name="al:android:url" content="app://saigah/cookies" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Cookie Policy",
            description:
              "Den här sidan beskriver cookiepolicyn för SAIGAH, och beskriver användningen av cookies på hemsidan.",
            url: "https://saigah.com/cookies",
            // Additional structured data relevant to the cookies page
          })}
        </script>
      </Helmet>
      <Menu />
      <div className={styles.contentContainer}>
        <Banner />
        <div className={styles.gdprSection}>
          <h1>Cookie Policy</h1>
          <p>
            <strong>Last updated:</strong> 2023-11-30
          </p>

          <h2>Introduction</h2>
          <p>
            Our website, SAIGAH.com, uses cookies and similar technologies to
            enhance your experience, analyze traffic, and for ad personalization
            and measurement. This policy explains how we use cookies and the
            choices you have.
          </p>

          <h2>What Are Cookies?</h2>
          <p>
            Cookies are small text files that websites place on your device as
            you are browsing. They are processed and stored by your web browser.
            In and of themselves, cookies are harmless and serve crucial
            functions for websites. Cookies can be "persistent" or "session"
            cookies, depending on how long they are used.
          </p>

          <h2>Why Do We Use Cookies?</h2>
          <p>
            We use cookies to make our website work, enhance performance, and
            improve user experience. Cookies also help us provide secure
            navigation, recognize your preferences, analyze your use of our
            products, and personalize content and advertisements.
          </p>

          <h2>Types of Cookies We Use</h2>
          <ul>
            <li>
              <strong>Strictly Necessary Cookies:</strong> These are required
              for the website to function and cannot be switched off. They are
              usually set in response to your actions, such as setting your
              privacy preferences, logging in, or filling in forms.
            </li>
            <li>
              <strong>Performance Cookies:</strong> These allow us to count
              visits and traffic sources so we can measure and improve the
              performance of our site.
            </li>
            <li>
              <strong>Functionality Cookies:</strong> These enable the website
              to provide enhanced functionality and personalization by
              remembering your choices.
            </li>
            <li>
              <strong>Targeting Cookies:</strong> These may be set through our
              site by our advertising partners. They may be used to build a
              profile of your interests and show you relevant adverts on other
              sites.
            </li>
            <li>
              <strong>Social Media Cookies:</strong> These are used to enable
              you to share pages and content that you find interesting on our
              website through third-party social networking and other websites.
            </li>
          </ul>

          <h2>Cookie Consent and Your Choices</h2>
          <p>
            When you first visit our website, we may ask for your consent to use
            cookies, excluding those necessary to provide the services you have
            requested. You can withdraw your consent at any time by deleting the
            cookies from your device and disabling cookies in your browser.
          </p>

          <h2>Managing Cookies</h2>
          <p>
            You can manage cookies through your browser's settings at any time.
            For more information on how to manage, disable, or delete cookies,
            please visit your browser's help pages or the interactive tutorials
            at www.aboutcookies.org.
          </p>

          <h2>Third-Party Cookies</h2>
          <p>
            In addition to our own cookies, we may also use various third-party
            cookies to report usage statistics, deliver advertisements on and
            through the website, and so on.
          </p>

          <h2>Changes to the Cookie Policy</h2>
          <p>
            We may update this Cookie Policy from time to time to reflect, for
            example, changes to the cookies we use or for other operational,
            legal, or regulatory reasons. Please re-visit this Cookie Policy
            regularly to stay informed about our use of cookies and related
            technologies.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions or comments about this policy or our use
            of cookies, please contact us at info@SAIGAH.com
          </p>
        </div>
        <Footer />

        <ScrollToTopButton />
      </div>
    </div>
  );
}

export default CookiesPage;
