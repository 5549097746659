import React from "react";
import styles from "./ContactForm.module.css";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.my5}>
      <div className={styles.container}>
        <div
          className={`${styles.row} ${styles.dFlex} ${styles.alignItemsStretch}`}
        >
          <div className={`${styles.col} ${styles.lg6} ${styles.firstCol}`}>
            <div className={styles.cardShadow}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle1}>Vart finns vi?</h3>
                <p className={styles.cardText1}>
                  Stockholm/Sundsvall, SE
                  <br />
                  info@saigah.com
                  <br />
                  +46 727 202 106
                </p>
                <div className={styles.mapResponsive}>
                  <iframe
                    title="location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130265.83887425221!2d17.817248407020944!3d59.32621310815854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f763119640bcb%3A0xa80d27d3679d7766!2sStockholm!5e0!3m2!1sen!2sse!4v1717254257037!5m2!1sen!2sse"
                    style={{ border: 0 }}
                    width="800"
                    height="600"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.lg6}`}>
            <div className={`${styles.h100} ${styles.cardShadow2}`}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle}>Hör av dig till oss!</h3>
                <p className={styles.cardText}>
                  Använd gärna formuläret nedan för att kontakta oss. Vi
                  återkommer till dig så snart som möjligt.
                </p>
                <form>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="text"
                      placeholder="Ditt namn"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="email"
                      placeholder="E-post"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="tel"
                      placeholder="Telefonnummer"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <textarea
                      rows={2}
                      placeholder="Meddelande"
                      className={styles.formControl}
                    ></textarea>
                  </div>
                  <div
                    className={styles.inputGroup}
                    style={{ textAlign: "center" }}
                  >
                    <input
                      className={styles.submit}
                      type="submit"
                      value={t("submit")}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
