import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import styles from "./footer.module.css";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <div className={styles["footer-content"]}>
        <div className={styles["footer-section"]}>
          <h2>SAIGAH</h2>
          <p>{t("Footer-texten")}</p>
          <div className={styles["social-icons"]}>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://instagram.com/saigahsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
        <div className={styles["footer-section"]}>
          <h3>{t("footer-service-inf")}</h3>
          <ul>
            <li>
              <Link to="/website">{t("footer-website")}</Link>
            </li>
            <li>
              <Link to="/ai">{t("footer-ai")}</Link>
            </li>
            <li>
              <Link to="/advertisement">{t("footer-adv")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles["footer-section"]}>
          <h3>Sitemap</h3>
          <ul>
            <li>
              <Link to="/">{t("footer-home")}</Link>
            </li>
            <li>
              <Link to="/contact">{t("footer-contact")}</Link>
            </li>

            <li>
              <Link to="/about">{t("footer-about")}</Link>
            </li>

            <li>
              <Link to="/news">{t("footer-news")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles["footer-section"]}>
          <h3>{t("footer-contact-inf")}</h3>

          <p>
            <FaPhoneAlt /> <a href="tel:+46727202106">+46 72 720 21 06</a>
          </p>
          <p>
            <FaEnvelope /> <a href="mailto:info@SAIGAH.com">info@SAIGAH.com</a>
          </p>
        </div>
      </div>
      <div className={styles["footer-bottom"]}>
        <div className={styles["footer-bottom-border"]}></div>
        <ul>
          <li>
            <Link to="/cookies">Cookies</Link>
          </li>
          <li>
            <Link to="/terms">{t("footer-terms")}</Link>
          </li>
          <li>
            <Link to="/gdprpage">{t("footer-gdpr")}</Link>
          </li>
        </ul>
        <p>© 2024 SAIGAH</p>
      </div>
    </footer>
  );
}

export default Footer;
