import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./menu.module.css";
import logo from "./assets/images/logans-removebg-preview.webp";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./languageselect.js";

function Menu() {
  const navRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);
  const { t } = useTranslation();

  const showNavbar = () => {
    navRef.current.classList.toggle(styles.responsive_nav);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      if (window.scrollY === 0) {
        setIsTransparent(true);
      } else {
        setIsTransparent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`${styles.header} ${
        isTransparent ? styles.transparent : `${styles.solid} ${styles.compact}`
      }`}
    >
      <Link to="/" className={styles.logoLink}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </Link>
      <nav ref={navRef} className={styles.nav}>
        <button
          className={`${styles.nav_btn} ${styles.nav_close_btn}`}
          onClick={showNavbar}
          aria-label="Close menu"
        >
          <FaTimes />
        </button>

        <div className={styles.topdropdown} onClick={toggleDropdown}>
          {t("services")} {showDropdown ? <FaCaretUp /> : <FaCaretDown />}
          <div
            className={`${styles.dropdown} ${
              showDropdown ? styles.showDropdown : ""
            }`}
            aria-expanded={showDropdown}
          >
            <Link to="/website" className={styles.arrowLink}>
              <div className={styles.arrowLinkTitle}>
                {t("website")} <span>&#8599;</span>
              </div>
              <div className={styles.description}>
                {t("website_description")}
              </div>
            </Link>
            <Link to="/ai" className={styles.arrowLink}>
              <div className={styles.arrowLinkTitle}>
                {t("seo")} <span>&#8599;</span>
              </div>
              <div className={styles.description}>{t("seo_description")}</div>
            </Link>
            <Link to="/advertisement" className={styles.arrowLink}>
              <div className={styles.arrowLinkTitle}>
                {t("service_maintenance")} <span>&#8599;</span>
              </div>
              <div className={styles.description}>
                {t("marketing_description")}
              </div>
            </Link>
          </div>
        </div>

        <Link to="/contact">{t("contact")}</Link>
        <Link to="/about">{t("about_us")}</Link>
        <Link to="/news">{t("news")}</Link>

        <div className={styles.mobileLanguageOptions}>
          <LanguageSelector isMobile={true} />
        </div>
      </nav>

      <div className={styles.desktopLanguageOptions}>
        <LanguageSelector isMobile={false} />
      </div>

      <button
        className={`${styles.nav_btn} ${styles.nav_open_btn}`}
        onClick={showNavbar}
        aria-label="Open menu"
      >
        <FaBars />
      </button>
    </header>
  );
}

export default Menu;
