import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styles from "./ArticleDetails.module.css";
import Menu from "./menu.js";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTranslation } from "react-i18next";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import NotFoundPage from "./notfoundpage";
import { FaRegClock } from "react-icons/fa";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner({ articleTitle }) {
  const { t } = useTranslation();
  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>
            {articleTitle || t("Article_banner_text")}
          </h1>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

const fetchArticleBySlug = async (slug) => {
  const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const endpoint = `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;

  const graphQLQuery = `{
        articlesForSaigahCollection(where: {slug: "${slug}"}) {
            items {
                sys {
                    id
                }
                title
                slug
                excerpt
                logo {
                    url
                }
                date
                categoriesCollection {
                    items {
                      category
                    }
                  }
                richTextBody {
                    json
                }
            }
        }
    }`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ query: graphQLQuery }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = await response.json();
    return data.data.articlesForSaigahCollection.items[0];
  } catch (error) {
    console.error("Error fetching article:", error);
    return null;
  }
};

const fetchAllArticles = async () => {
  const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const endpoint = `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;

  const graphQLQuery = `{
        articlesForSaigahCollection {
            items {
                sys {
                    id
                }
                title
                slug
                logo {
                    url
                }
                date
                categoriesCollection {
                    items {
                      category
                    }
                  }
            }
        }
    }`;

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ query: graphQLQuery }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = await response.json();
    return data.data.articlesForSaigahCollection.items;
  } catch (error) {
    console.error("Error fetching all articles:", error);
    return [];
  }
};

const formatText = (text) => {
  let formattedText = text;

  formattedText = formattedText.replace(/;;/g, "<br><br>");
  formattedText = formattedText.replace(/;/g, "<br>");
  return formattedText;
};

function extractTextFromJson(json) {
  let text = "";
  if (json.content) {
    json.content.forEach((content) => {
      if (content.content && Array.isArray(content.content)) {
        text += extractTextFromJson(content); // Recursively extract text
      } else if (content.nodeType === "text") {
        text += content.value + " ";
      }
    });
  }
  return text;
}

function calculateReadingTime(json) {
  const text = extractTextFromJson(json);
  const wordsPerMinute = 200; // Average reading speed
  const words = text.trim().split(/\s+/).length; // Splits on whitespace
  const minutes = Math.ceil(words / wordsPerMinute);
  return minutes;
}

const ArticleDetails = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchArticleBySlug(slug), fetchAllArticles()]).then(
      ([currentArticle, allArticles]) => {
        if (currentArticle) {
          setArticle(currentArticle);
          const otherArticles = allArticles.filter(
            (a) => a.sys.id !== currentArticle.sys.id
          );
          const shuffled = otherArticles.sort(() => 0.5 - Math.random());
          setRelatedArticles(shuffled.slice(0, 3));
        } else {
          setArticle(null);
        }
        setLoading(false);
      }
    );
  }, [slug]);

  if (loading) {
    return <div className={styles.loadingContainer}>Loading...</div>;
  }

  if (!article) {
    return <NotFoundPage />;
  }

  const europeanDate = article.date
    ? new Date(article.date)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-")
    : "";

  return (
    <div>
      <Menu />
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.excerpt} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.excerpt} />
        <meta property="og:image" content={article.logo.url} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.excerpt} />
        <meta name="twitter:image" content={article.logo.url} />
      </Helmet>
      <Banner articleTitle={article.title} />
      <div className={styles.articleContainer}>
        <img
          src={article.logo.url}
          alt={article.title}
          className={styles.articleImage}
        />
        <div className={styles.infoContainer}>
          <p className={styles.articleDate}>{t("published")}:</p>
          {europeanDate}
          <p className={styles.readTime}>
            <FaRegClock className={styles.clockIcon} />{" "}
            {calculateReadingTime(article.richTextBody.json)} {t("readTime")}
          </p>
        </div>
        <div className={styles.articleText}>
          {documentToReactComponents(article.richTextBody.json)}
        </div>
        <div className={styles.categories}>
          {t("category")}:{" "}
          {article.categoriesCollection.items.map((cat, index) => (
            <span key={index} className={styles.categoryTag}>
              {cat.category}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.relatedArticles}>
        <h2>{t("Article_related_text")}</h2>
        {relatedArticles.length > 0 && (
          <div className={styles.relatedArticlesGrid}>
            {relatedArticles.map((relArticle) => (
              <Link
                to={`/${relArticle.slug}`}
                key={relArticle.sys.id}
                className={styles.relatedArticleLink}
              >
                <div className={styles.relatedArticleCard}>
                  <div className={styles.articleImageContainer}>
                    <img
                      src={relArticle.logo.url}
                      alt={relArticle.title}
                      className={styles.relatedArticleImage}
                    />
                    <div className={styles.relatedArticleCategories}>
                      {relArticle.categoriesCollection?.items.map(
                        (cat, index) => (
                          <div
                            key={index}
                            className={styles.relatedCategoryDateWrapper}
                          >
                            <span className={styles.relatedArticleDate}>
                              {new Date(article.date)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                                .replace(/\//g, "-")}
                            </span>
                            <span className={styles.relatedCategoryTag}>
                              {cat.category}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className={styles.relatedArticleLink}>
                    <h3>{relArticle.title}</h3>
                  </div>
                  <div className={styles.learnMore}>
                    {t("learnMore")}{" "}
                    <span className={styles.arrow}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 12H19"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 5L19 12L12 19"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>

      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default ArticleDetails;
