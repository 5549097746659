import React from "react";
import styles from "./notfoundpage.module.css";
import Menu from "./menu.js";
import Footer from "./footer";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("404_banner_text")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("404_banner_text2")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}></div>
    </div>
  );
}

const NotFoundPage = () => {
  return (
    <div>
      <Menu />
      <Banner />
      <Footer />
    </div>
  );
};

export default NotFoundPage;
