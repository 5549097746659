import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./footer";
import styles from "./news.module.css";
import Menu from "./menu.js";
import { FaArrowDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

const News = () => {
  const [articles, setArticles] = useState([]);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [sortOrder, setSortOrder] = useState("newest");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;
  const { t } = useTranslation();

  useEffect(() => {
    fetchArticles();
    window.scrollTo(0, 0);
  }, [t]);

  useEffect(() => {
    filterAndSortArticles();
  }, [searchQuery, selectedCategory, sortOrder, articles, t]);

  useEffect(() => {
    updateCurrentPage();
  }, [displayedArticles, currentPage]);

  const fetchArticles = async () => {
    const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
    const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
    const endpoint = `https://graphql.contentful.com/content/v1/spaces/${spaceId}/`;

    const query = `
    {
      articlesForSaigahCollection(order: date_DESC) {
        items {
          sys {
            id
          }
          title
          slug
          date
          excerpt
          logo {
            url
          }
          categoriesCollection {
            items {
              category
            }
          }
        }
      }
    }
    `;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ query }),
      });
      const { data, errors } = await response.json();

      if (errors) {
        console.error(errors);
        return;
      }

      setArticles(data.articlesForSaigahCollection.items);
      setDisplayedArticles(data.articlesForSaigahCollection.items);
      const allCategories = new Set(
        data.articlesForSaigahCollection.items.flatMap((article) =>
          article.categoriesCollection.items.map((item) => item.category)
        )
      );
      setSelectedCategory(t("allCategories"));
      setCategories([t("allCategories"), ...Array.from(allCategories)]);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const filterAndSortArticles = () => {
    const filteredArticles = articles
      .filter((article) => {
        return (
          (selectedCategory === t("allCategories") ||
            article.categoriesCollection.items.some(
              (cat) => cat.category === selectedCategory
            )) &&
          (article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            article.excerpt.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
      });

    setDisplayedArticles(filteredArticles);
    setCurrentPage(1);
  };

  const updateCurrentPage = () => {
    const pageCount = Math.ceil(displayedArticles.length / articlesPerPage);
    if (currentPage > pageCount) {
      setCurrentPage(pageCount > 0 ? pageCount : 1);
    }
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleSortOrderChange = (e) => setSortOrder(e.target.value);

  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 510, behavior: "smooth" });
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = displayedArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.contentContainer}>
      <Menu />
      <Helmet>
        <title>Senaste nyheterna - SAIGAH</title>
        <meta
          name="description"
          content="Håll dig uppdaterad med de senaste nyheterna och insikterna från SAIGAH. Täcker ämnen från branschtrender till djupgående analyser."
        />
        <meta
          name="keywords"
          content="senaste nyheterna, branschtrender, djupgående analyser, SAIGAH nyheter"
        />
        <link rel="canonical" href="https://saigah.com/news" />
        <meta property="og:title" content="Senaste nyheterna - SAIGAH" />
        <meta
          property="og:description"
          content="Håll dig uppdaterad med de senaste nyheterna och insikterna från SAIGAH."
        />
        <meta property="og:url" content="https://saigah.com/news" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Senaste nyheterna - SAIGAH" />
        <meta
          name="twitter:description"
          content="Håll dig uppdaterad med de senaste nyheterna och insikterna från SAIGAH."
        />
      </Helmet>

      <Banner />
      <div className={styles.searchAndSortContainer}>
        <div className={styles.searchAndSortContent}>
          <SearchContainer
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
          />
          <SortDropdowns
            sortOrder={sortOrder}
            handleSortOrderChange={handleSortOrderChange}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
            categories={categories}
          />
        </div>
      </div>
      <ArticlesContainer currentArticles={currentArticles} t={t} />
      <Pagination
        articlesTotal={displayedArticles.length}
        articlesPerPage={articlesPerPage}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("News_banner_text")}</h1>
          <h2 className={styles["banner-heading1"]}>
            {t("News_banner_text2")}
          </h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
};

const SearchContainer = ({ searchQuery, handleSearchChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        placeholder={t("searchPlaceholder")}
        value={searchQuery}
        onChange={handleSearchChange}
        className={styles.searchInput}
      />
    </div>
  );
};

const SortDropdowns = ({
  sortOrder,
  handleSortOrderChange,
  selectedCategory,
  handleCategoryChange,
  categories,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.sortDropdownWrapper}>
      <div className={styles.sortDropdownContainer}>
        <select
          onChange={handleSortOrderChange}
          value={sortOrder}
          className={styles.sortDropdown}
        >
          <option value="newest">{t("newestFirst")}</option>
          <option value="oldest">{t("oldestFirst")}</option>
        </select>
      </div>
      <div className={styles.sortDropdownContainer}>
        <select
          onChange={handleCategoryChange}
          value={selectedCategory}
          className={styles.sortDropdown}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ArticlesContainer = ({ currentArticles, t }) => {
  return (
    <div className={styles.articlesContainer}>
      {currentArticles.map((article, index) => (
        <Link
          to={`/${article.slug}`}
          key={index}
          className={styles.articleLink}
        >
          <div className={styles.articlePreview}>
            <div className={styles.articleImageContainer}>
              <img
                src={article.logo.url}
                alt={article.title}
                className={styles.articleImage}
              />
              <div className={styles.articleDateCategory}>
                <span className={styles.articleDate}>
                  {new Date(article.date)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    .replace(/\//g, "-")}
                </span>
                {article.categoriesCollection.items.map((cat, catIndex) => (
                  <span key={catIndex} className={styles.categoryTag}>
                    {cat.category}
                  </span>
                ))}
              </div>
            </div>
            <div className={styles.articleTextContent}>
              <h2 className={styles.articleTitle}>{article.title}</h2>
              <p className={styles.articleSummary}>
                {article.excerpt.substring(0, 120)}...
              </p>
            </div>
            <div className={styles.learnMore}>
              {t("learnMore")}{" "}
              <span className={styles.arrow}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 5L19 12L12 19"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

const Pagination = ({
  articlesTotal,
  articlesPerPage,
  paginate,
  currentPage,
}) => {
  const pageCount = Math.ceil(articlesTotal / articlesPerPage);
  let startPage, endPage;

  if (pageCount <= 3) {
    startPage = 1;
    endPage = pageCount;
  } else {
    if (currentPage <= 2) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage + 1 >= pageCount) {
      startPage = pageCount - 2;
      endPage = pageCount;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
  const { t } = useTranslation();

  return (
    <nav>
      <ul className={styles.pagination}>
        {currentPage > 1 && (
          <li className={styles.pageItem}>
            <button
              onClick={() => paginate(currentPage - 1)}
              className={styles.pageLink}
            >
              {t("previous")}
            </button>
          </li>
        )}
        {pages.map((page) => (
          <li key={page} className={styles.pageItem}>
            <button
              onClick={() => paginate(page)}
              className={`${styles.pageLink} ${
                page === currentPage ? styles.active : ""
              }`}
            >
              {page}
            </button>
          </li>
        ))}
        {currentPage < pageCount && (
          <li className={styles.pageItem}>
            <button
              onClick={() => paginate(currentPage + 1)}
              className={styles.pageLink}
            >
              {t("next")}
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default News;
